import React, {useState} from 'react';
import Snippet from "./Snippet";
const NkTbItem = (props) => {

        const getPrice = (quantity,item) => {
            const price = item.discount_price > 0 ? item.price - item.discount_price:item.price;
            return price * quantity;
        }

        return (
            <>
                {props.selectedProducts.length > 0 && props.selectedProducts.map((item,index) => {
                    return <div key={index} className="nk-tb-item">

                        <div className="nk-tb-col user-info tb-col-sm">

                            <span className="title tb-lead">{item.name} </span>
                            <span>{item.code} / {item.company_code}</span>
                        </div>
                        <div className="nk-tb-col">
                            <span className="tb-lead">{item.capacity + item.unit}</span>
                        </div>
                        <div className="nk-tb-col">
                            <Snippet onChange={(e) => props.onChange(index,parseInt(e.target.value))} increase={() => props.increase(index)} quantity={item.quantity} decrease={() => props.decrease(index)}/>
                        </div>
                        <div className="nk-tb-col tb-col-md">
                            ₹ {getPrice(item.quantity,item)}
                        </div>
                        <div className="nk-tb-col ">
                            <ul className="nk-tb-actions justify-start">
                                <li className="mr-n1">
                                    <button
                                        type="button"
                                        onClick={()=> props.removeFromCart(index)}
                                        className="btn btn-danger"
                                    >
                                        <em className="icon ni ni-cross">
                                        </em>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                })}
            </>
        );
}

export default NkTbItem;
