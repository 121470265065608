import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from 'react-redux';
import {userService} from "../../../services/service-user";

const Logout = () => {
    userService.logout().then((res) => {
        localStorage.removeItem('user');
        localStorage.removeItem('login_time');
        window.location.reload();
    })

}


const Header = () => {
    const {user,market} = useSelector(state => state);

        return (
            <>
                <div className="nk-header nk-header-fixed is-light">
                    <div className="container-fluid">
                        <div className="nk-header-wrap">
                            <div className="nk-menu-trigger d-xl-none ml-n1">
                                <Link to="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em
                                    className="icon ni ni-menu"></em></Link>
                            </div>
                            <div className="nk-header-brand d-xl-none">
                                {market.marketData && market.marketData.prime ?
                                    <div className="prime-member">
                                        <img className="logo-dark logo-img logo-img-lg" src="../../../../assets/images/prime.png"
                                             srcSet="../../../../assets/images/prime.png" alt="logo"/>
                                    </div>
                                    :
                                    <Link to="/" className="logo-link">
                                    <img className="logo-light logo-img" src="../../../../assets/images/vendor.png"
                                         srcSet="../../../../assets/images/logo-space.png" alt="logo"/>
                                        <img className="logo-dark logo-img" src="../../../../assets/images/vendor.png"
                                             srcSet="../../../../assets/images/vendor.png" alt="logo-dark"/>
                                    </Link>
                                }
                            </div>
                            <ul className="d-flex flex-row align-center">
                                <li className="nk-menu-item mr-5">
                                    <Link to="/" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu">
                                        <img className="logo-dark logo-img" src="../../../../assets/images/vendor.png"
                                             srcSet="../../../../assets/images/vendor.png" alt="logo-dark"/>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/" className="nk-menu-link">
                                        <span className="nk-menu-icon">
                                            <em className="icon ni ni-dashboard-fill">
                                            </em>
                                        </span>
                                        <span className="nk-menu-text pl-1">Dashboard</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/orders" className="nk-menu-link">
                                        <span className="nk-menu-icon">
                                            <em className="icon ni ni-bag-fill">
                                            </em>
                                        </span>
                                        <span className="nk-menu-text pl-1">Sales</span>
                                    </Link>
                                </li>
                                <li className="nk-menu-item">
                                    <Link to="/quick-orders" className="nk-menu-link">
                                        <span className="nk-menu-icon">
                                            <em className="icon ni ni-bag-fill">
                                            </em>
                                        </span>
                                        <span className="nk-menu-text pl-1">Quick Orders</span>
                                    </Link>
                                </li>
                                {/*<li className="nk-menu-item">*/}
                                {/*    <Link className="btn btn-primary py-1 px-3" to="/pointOfSale">*/}
                                {/*        Point Of Sale*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                            {!market.marketData || !market.marketData.active &&
                            <div className="nk-header-search ml-3 ml-xl-0">
                                <Link className="btn btn-primary" to="/">
                                    <em className="icon ni ni-home">
                                    </em>
                                </Link>&nbsp;
                                <h5>You don't have any active stores</h5>
                            </div>
                            }
                            <div className="nk-header-tools">
                                <ul className="nk-quick-nav">
                                    <li className="dropdown user-dropdown">
                                        <Link to="#" className="dropdown-toggle mr-n1" data-toggle="dropdown">
                                            <div className="user-toggle">
                                                <div className="user-avatar sm">
                                                    <em className="icon ni ni-user-alt"></em>
                                                </div>
                                                <div className="user-info d-none d-xl-block">
                                                    <div className="user-status user-status-active">
                                                        Vendor  {market.marketData && market.marketData.prime && '(Prime)'}
                                                    </div>
                                                    <div className="user-name dropdown-indicator">
                                                        {user.currentUser.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                                <div className="user-card">
                                                    <div className="user-avatar">
                                                        <em className="icon ni ni-user-alt"></em>
                                                    </div>
                                                    <div className="user-info">
                                                        <span className="lead-text">{user.currentUser.name}</span>
                                                        <span className="sub-text">{user.currentUser.phone}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dropdown-inner">
                                                <ul className="link-list">
                                                    <li><Link to="/profile"><em
                                                        className="icon ni ni-user-alt"></em><span>View Profile</span></Link>
                                                    </li>
                                                    {/*<li><Link to="html/ecommerce/user-profile.html"><em
                                                        className="icon ni ni-setting-alt"></em><span>Account Setting</span></Link>
                                                    </li>
                                                    <li><Link to="html/ecommerce/user-profile.html"><em
                                                        className="icon ni ni-activity-alt"></em><span>Login Activity</span></Link>
                                                    </li>*/}
                                                </ul>
                                            </div>
                                            <div className="dropdown-inner">
                                                <ul className="link-list">
                                                    <li>
                                                        <button className="btn btn-light btn-trans px-0" onClick={Logout}>
                                                            <em className="icon ni ni-signout"></em>
                                                            <span>Sign out</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
}

export default Header;
