import React from 'react';
import { API_BASE_URL_ROOT,BASE_URL } from '../helper/globals';
import axios from 'axios';
import uuid from 'react-uuid';
import {store} from '../store';

const state = () => {
    return store.getState();
}

async function uploadFile(file){
    return new Promise(function(resolve, reject) {

        const formData = new FormData();
        formData.append('file',file);
        formData.append('uuid',uuid());
        formData.append('field','image');
        formData.append('api_token',state().user.currentUser.apiToken);

        axios.post(API_BASE_URL_ROOT + 'uploads/store', formData)
        .then((response) => {
            const json = response.data;
            console.log(json);
            if (json.success) { // response success checking logic could differ
                resolve(json.data);
            } else {
                reject({error:json.message});
            }
        })
        .catch((error) => {
            console.log(error);
            reject({error});
        })
});
}

async function getFieldsData(){
    return new Promise(function(resolve, reject) {

            axios.get(API_BASE_URL_ROOT + 'fields', { 'params':{'orderBy':'updated_at','sortedBy': 'desc'}})
            .then((response) => {
                const json = response.data;
                console.log(json);
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


async function getCategories(filterData = null){
    return new Promise(function(resolve, reject) {
            axios.get(API_BASE_URL_ROOT + 'categories')
            .then((response) => {
                const json = response.data;
                console.log(json);
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    resolve(json.data);
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};


export const mediaService = {
    uploadFile,
    getFieldsData,
    getCategories
};