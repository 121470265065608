import axios from 'axios';
import { BASE_URL,API_BASE_URL } from '../helper/globals';
import {useDispatch,useSelector} from 'react-redux';
import {store} from '../store';

const state = () => {
  return store.getState();
}
async function checkPhone(loginInput){
  const { phone } = loginInput;
  console.log(loginInput);
  return new Promise((resolve, reject) => { // don't forget to use dispatch here!
  axios.post(API_BASE_URL + 'verifyPhone', loginInput)
      .then((response) => {
        const json = response.data;
        if (json.success) { // response success checking logic could differ
          console.log(json.data.otp);
          resolve(json.data);
        } else {
          reject({error:'Something wrong'});
        }
      })
      .catch((err) => {
        console.log(err);
        reject({error:err});
      });
  });
};


async function logout(){
  const user = state().user;
  const device_id = localStorage.getItem('login_time');
  return new Promise((resolve, reject) => { // don't forget to use dispatch here!
  axios.get(API_BASE_URL + 'logout', {'params': {
        device_id,
          api_token:user.currentUser.apiToken
      }
  })
      .then((response) => {
        const json = response.data;
        console.log(json);
        if (json.success) { // response success checking logic could differ
          resolve(json.data);
        } else {
          reject({error:'Something wrong'});
        }
      })
      .catch((err) => {
        console.log(err);
        reject({error:err});
      });
  });
};

async function checkOTP(loginInput){
  const { phone,otp } = loginInput;
  console.log(loginInput);
  return new Promise((resolve, reject) => { // don't forget to use dispatch here!
    return fetch(API_BASE_URL + 'verifyOTP', {
      method: 'POST',
      headers: {  // these could be different for your API call
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginInput),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.success) { // response success checking logic could differ
          resolve(json.data);
        } else {
          console.log(json);
          reject({error:'Something wrong'});
        }
      })
      .catch((err) => {
        console.log(err);
        reject({error:err});
      });
  });
};

async function getNotifications({user}){
  return new Promise(function(resolve, reject) {
          const query_params = {
              api_token:user.apiToken,
              limit:10,
              search:'notifiable_id:'+user.id,
              searchFields:'notifiable_id:=',
              orderBy:'id',
              sortedBy:'desc',
          };
          console.log('notification load');
          axios.get(BASE_URL + 'api/notifications', { 'params':query_params})
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              console.log(error);
              reject({error});
          })
  });
};




async function getCounts(){
  return new Promise(function(resolve, reject) {
          const query_params = {
              api_token:state().user.currentUser.apiToken,
          };
          console.log('notification load');
          axios.get(BASE_URL + 'api/manager/dashboard/'+state().user.currentUser.id, { 'params':query_params})
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              console.log(error);
              reject({error});
          })
  });
};


async function updateUser(userData){
  return new Promise(function(resolve, reject) {
          userData.api_token = state().user.currentUser.apiToken;
          console.log('notification load');
          axios.post(BASE_URL + 'api/manager/users/'+state().user.currentUser.id,userData)
          .then((response) => {
              const json = response.data;
              if (json.success) { // response success checking logic could differ
                  resolve(json.data);
              } else {
                  reject({error:'Something wrong'});
              }
          })
          .catch((error) => {
              console.log(error);
              reject({error});
          })
  });
};


async function updateToken(device_id,device_token){
    return new Promise(function(resolve, reject) {
        const data = {
            api_token:state().user.currentUser.apiToken,
            device_id,
            device_token
        };
        //console.log('notification load');
        axios.post(BASE_URL + 'api/manager/tokenUpdate/',data)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:'Something wrong'});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

export const userService = {
    checkOTP,
    checkPhone,
    getNotifications,
    logout,
    getCounts,
    updateUser,
    updateToken
};
