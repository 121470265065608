import React, {useEffect, useReducer, useRef, useState} from 'react';
import PageHeading from "../../components/header/PageHeading";
import ProductItem from "./components/ProductItem";
import {marketService} from '../../../services/service-markets';
import {useSelector} from "react-redux";
const statusBadge = (order) => {
    if(!order.active){
        return (<span className="badge badge-sm badge-dot has-bg badge-danger  d-mb-inline-flex">Cancelled</span>);
    }


    switch(order.order_status_id){
        case 1:
            return (<span className="badge badge-sm  has-bg badge-secondary mb-1 d-mb-inline-flex">Order Received</span>);
            break;
        case 2:
            return (<span className="badge badge-sm  has-bg badge-info mb-1 d-mb-inline-flex">Preparing</span>);
            break;
        case 3:
            return (<span className="badge badge-sm  has-bg badge-primary mb-1  d-mb-inline-flex">Ready</span>);
            break;
        case 4:
            return (<span className="badge badge-sm has-bg badge-warning mb-1  d-mb-inline-flex">On the way</span>);
            break;
        case 5:
            return (<span className="badge badge-sm has-bg badge-success mb-1  d-mb-inline-flex">Delivered</span>);
            break;

    }
}

const nextStatus = (order) => {
    let status_id = order.order_status_id;
    const status_list = {
        '1': {id:1,name:'Received',color:'secondary'},
        '2': {id:2,name:'Preparing',color:'info'},
        '3': {id:3,name:'Ready',color:'primary'},
        '4': {id:4,name:'On the way',color:'warning'},
        '5': {id:5,name:'Delivered',color:'success'}
    };

    if(status_id === 3 && !order.delivery_address){
        status_id = 4;
    }

    return status_id != 5 ? status_list[status_id + 1]:status_list[5];
}

const statusList = () => {
    return [
        {status:'Order Received',id:1,color:'secondary'},
        {status:'Preparing',id:2,color:'info'},
        {status:'Ready',id:3,color:'primary'},
        {status:'On the way',id:4,color:'warning'},
        {status:'Delivered',id:5,color:'success'}
        ];

}

const OrderDetail = (props) => {
        const scannerRef = useRef();
        const order_id = props.match.params.id;
        const [orderData,setOrderData] = useState(null);
        const [loading,setLoading] = useState(false);
        const {market} = useSelector(state => state);
        const [driverSelected,setDriverSelected] = useState('');
        const [scanned,setScanned] = useState([]);
         const [_, forceUpdate] = useReducer((x) => x + 1, 0);
        useEffect(() => {
            getOrderData();

        }, []);

        const getOrderData = () => {
            setLoading(true);
            marketService.getOrderDetails(order_id)
            .then((res) => {
                setOrderData(res);
                if(res.driver_id){
                    setDriverSelected(res.driver_id);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            });
        }

        const updateDriver = (driver_id) => {
            setDriverSelected(driver_id);
        }

        const updateStatus = (status) => {
            if(status === 4 && !driverSelected){
                alert('Please select a driver');
                return;
            }

            if(window.confirm('Are you sure you want to update order status?')){
                marketService.updateOrderDetails({order_id,status,driverSelected})
                .then((res) => {
                    getOrderData();
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        }

        const cancelOrder = () => {
            if(window.confirm('Are you sure you want to cancel this order?')){
                marketService.cancelOrder(order_id)
                    .then((res) => {
                        getOrderData();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }

        const allScanned = () => {
            if(orderData?.order_status_id < 3 ) return true;

            let all_done = true;
            orderData.product_orders.map((item) => {
                 if(!scanned.includes(item.product.id)){
                     all_done = false;
                 }
            });
            return all_done;
        }

        const scanProduct = (code) => {
            let scanned_products = scanned;
            let found = false;
            orderData.product_orders.map((item) => {
                if(item.product.code === code || item.product.company_code === code){
                    scanned_products.push(item.product.id);
                    found = true;
                }
            });
            if(!found){
                alert('This product does not match any products in list!');
                scannerRef.current.value = '';
                return;
            }

            setScanned(scanned_products);
            console.log(scanned);
            scannerRef.current.value = '';
            forceUpdate();
            return true;
        }

        const skipProduct = (id) => {
            let scanned_products = scanned;
            scanned_products.push(id);
            setScanned(scanned_products);
            forceUpdate();
            return true;
        }
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <PageHeading name={"Sale Details"}/>
                    <div className="nk-block">
                        <div className="card">
                            <div className="card-aside-wrap">
                                <div className="card-content">

                                    {!loading && orderData &&
                                    <div className="card-inner">
                                        <div className="nk-block">
                                            <div className="d-flex flex-row justify-between w-100 align-start">
                                                <div className="nk-block-head">
                                                    <h5 className="title">Sale Detail</h5>
                                                    <p>{statusBadge(orderData)}</p>
                                                </div>

                                                {orderData.active && orderData.order_status_id !== 5 && <button className="btn btn-primary btn-danger" onClick={cancelOrder}>Cancel</button>}

                                            </div>

                                            <div className="d-flex flex-row mb-5">
                                                <div className="profile-ud-list">
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud">
                                                            <span className="profile-ud-label">Client</span>
                                                            <span className="profile-ud-value">{orderData.user.name}</span>
                                                        </div>
                                                    </div>

                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud">
                                                            <span className="profile-ud-label">Contact Number</span>
                                                            <span className="profile-ud-value">{orderData.user.phone}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud">
                                                            <span className="profile-ud-label">Order ID</span>
                                                            <span className="profile-ud-value">{orderData.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud">
                                                            <span className="profile-ud-label">Total Items</span>
                                                            <span className="profile-ud-value">{orderData.product_orders.length}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud">
                                                            <span className="profile-ud-label">Pay</span>
                                                            <span className="profile-ud-value">Rs. {orderData.payment.price}</span>
                                                        </div>
                                                    </div>
                                                    <div className="profile-ud-item">
                                                        <div className="profile-ud">
                                                            <span className="profile-ud-label">Payment Status</span>
                                                            <span className="profile-ud-value">{orderData.payment.method}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="profile-ud-list">
                                                    <div className=" d-flex flex-column float-left">
                                                        <span className="profile-ud-value">
                                                            <b>Address</b>:
                                                            {orderData.delivery_address ? orderData.delivery_address.address:'Address Not Available'}
                                                        </span>
                                                        <span className="profile-ud-value">
                                                            <b>Complete Address</b>:
                                                            {orderData.delivery_address && orderData.delivery_address.complete_address}
                                                        </span>
                                                        <span className="profile-ud-value">
                                                            <b>Floor</b>:
                                                            {orderData.delivery_address && orderData.delivery_address.floor}
                                                        </span>
                                                        <span className="profile-ud-value">
                                                            <b>Instructions</b>:
                                                            {orderData.delivery_address && orderData.delivery_address.instructions}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" w-100">
                                            <div className="nk-block-head nk-block-head-line">
                                                <h6 className="title overline-title text-base">Ordered Products</h6>
                                            </div>
                                            <div className="nk-tb-list is-separate mb-3">
                                                <div className="nk-tb-item nk-tb-head">
                                                    <div className="nk-tb-col"><span>#</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span>Product Name</span></div>
                                                    <div className="nk-tb-col tb-col-md"><span>Brand</span></div>
                                                    <div className="nk-tb-col"><span>Price</span></div>
                                                    <div className="nk-tb-col tb-col-sm"><span>Capacity</span></div>
                                                    <div className="nk-tb-col"><span>Quantity</span></div>
                                                    <div className="nk-tb-col"><span>Scan Status</span></div>
                                                    <div className="nk-tb-col"><span>#</span></div>
                                                </div>
                                                {orderData.product_orders.length > 0 && orderData.product_orders.map((value,index) => <ProductItem skipme={() => skipProduct(value.product.id)} active={orderData.active} status={orderData.order_status_id} scanned={scanned.includes(value.product.id)} item={value} product={value.product}/>)}
                                            </div>
                                        </div>
                                        {orderData.active && <div className="nk-block">
                                            <div className="nk-block-head nk-block-head-line">
                                                <h6 className="title overline-title text-base">Order Status</h6>
                                            </div>
                                        <div className="float-left w-100 d-flex flex-row justify-between pb-4">
                                            <div className="float-left w-100 d-flex flex-row align-center">
                                                {statusList(orderData).map((status,index) => {
                                                    if((index+1) === 4 && !orderData.delivery_address){
                                                        return <></>;
                                                    }

                                                    return (
                                                        <><span className={`orderText badge badge-sm badge-dot has-bg badge-${orderData.order_status_id >= (index+1) ? 'success':'secondary'} d-none d-mb-inline-flex`}>{status.status}</span>
                                                        {(index + 1) < statusList(orderData).length && <span className="spaceLine "/>}</>
                                                    )
                                                })}

                                            </div>
                                            <div className={'mr-1'}>
                                                {orderData.active && nextStatus(orderData).id === 4 && allScanned() ?
                                                    <>
                                                            <select className="select2 form-control"
                                                                    onChange={(val) => updateDriver(val.target.value)}
                                                                    id="driver_id" name="driver_id">
                                                                <option value=""
                                                                        selected={true}>Select Driver
                                                                </option>
                                                                {market.marketData.drivers && market.marketData.drivers.map((driver,index) =>
                                                                    <option value={driver.id} selected={driver.id === orderData.driver_id}>{driver.phone}</option>)}

                                                            </select>
                                                    </>
                                                    :
                                                    <>
                                                        <span
                                                            className="badge badge-sm badge-dot has-bg badge-danger d-none d-mb-inline-flex">Cancelled</span>
                                                    </>
                                                }
                                            </div>
                                            {
                                                orderData.order_status_id != 5 && allScanned() && <button className={`btn btn-${nextStatus(orderData).color} mr-1`} onClick={() => updateStatus(nextStatus(orderData).id)}>{nextStatus(orderData).name} <em className="icon ni ni-arrow-right"></em></button>
                                            }
                                            {
                                                !allScanned() &&
                                                <input
                                                    ref={scannerRef}
                                                    type="text"
                                                    placeholder="Scan Barcode..."
                                                //    onChange={event => {this.setState({query: event.target.value})}}
                                                    onKeyPress={event => {
                                                        if(!event.target.value) return;

                                                        if (event.key === 'Enter') {
                                                           scanProduct(event.target.value)
                                                        }
                                                    }}
                                                />
                                            }
                                            {/*<button className="btn btn-primary">Done</button>*/}
                                        </div>

                                        </div>}
                                    </div>}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default OrderDetail;
