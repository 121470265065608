import React, {Component} from 'react';
import { Link } from 'react-router-dom';

const ProductItem = (props) => {

        console.log(props.scanned);
        return (
            <>

                <div className="nk-tb-item">
                    <div className="nk-tb-col">
                        <img src={props.product.has_media ? props.product.media[0].thumb:''} alt="" width="50" height="50" className="thumb"/>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                         <span className="title">{props.product.name}</span>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                        <span className="title">{props.product.brand.name}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">Rs. {props.item.price}</span>
                    </div>
                    <div className="nk-tb-col">
                        {props.product.variable ?
                            <span className="tb-sub">{props.item.variations.capacity + ' - '+props.item.variations.unit}</span>
                            :
                            <span className="tb-sub">{props.product.capacity + ' - '+props.product.unit}</span>}
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-sub">{props.item.quantity}</span>
                    </div>
                    <div className="nk-tb-col">
                        {props.scanned ? <span className="tb-sub text-success">Scan Done</span>:<span className="tb-sub text-danger">Not scanned</span>}
                    </div>
                    <div className="nk-tb-col">
                        {props.status === 3 && props.active && <div>
                            {!props.scanned &&
                            <button onClick={props.skipme} className="btn btn-info">Skip Scan</button>}
                        </div>}
                    </div>
                </div>
            </>
        );
}

export default ProductItem;
