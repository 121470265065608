import React, {Component, useEffect, useState} from 'react';
import {Switch, BrowserRouter} from 'react-router-dom';
import './App.css';
import MainRoute from "./views/components/MainRoute";
import Home from "./views/main/home/Home";
import Login from "./views/auth/login/Login";
import AuthRoute from "./views/components/AuthRoute";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import PosRoute from "./views/components/PosRoute";
const App = (props) => {
  const [ready,setReady] = useState(false);

    return (
        <>
          <BrowserRouter>
            <Switch>
              <AuthRoute path="/login" name="login" component={Login}/>
              <PosRoute path="/pointOfSale" name="pointOfSale" component={PosRoute}/>
              <MainRoute path="/" name="home" component={Home}/>
            </Switch>
          </BrowserRouter>
          <ToastContainer />
      </>
    );
}

export default App;
