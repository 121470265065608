import React,{useState} from 'react';
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import Snippet from "./components/Snippet";
import AutoCompleteProducts from "../../components/inputs/AutoCompleteProducts";
import {toast} from "react-toastify";
import {cartService} from "../../../services/service-cart";
import {posService} from "../../../services/service-pos";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const PointOfSale = (props) =>{
    const {market} = useSelector(state => state);
    const [selectedProducts,setSelectedProducts] = useState([]);
    const [selectedCustomer,setSelectedCustomer] = useState(null);
    const [loading,setLoading] = useState(false);
    const [creating,setCreating] = useState(false);
    const [phone,setPhone] = useState('');

    const chosenValue = (data) => {
        if(data.available_stock <= 0){
            toast.error("Product is not in stock")
            return;
        }

       // const found = selectedProducts.filter((item) => item.)
        console.log(data);
        setSelectedProducts(selectedProducts.concat({
            ...data,
            quantity:1,
        }));
        toast.success(data.code + ' Added to cart');
    }


    const findCustomer = () => {
        if(!phone){
            toast.error('Please enter customer phone number');
            return;
        }
        posService.findCustomer(phone).then((data) => setSelectedCustomer(data));
    }

    const createOrder = (order_hash = '',gateway_id = '') => {

        if(!selectedCustomer){
            toast.error('Please select customer');
            return;
        }

        if(selectedProducts.length <=0 ){
            toast.error('Please select at least one product');
            return;
        }

        if(loading){
            return;
        }
        if(creating){
            return;
        }

        const methodType = 'pickup';
        let method_type = '';
        let paymentMethod = 'cash';
        switch(methodType){
            case 'pickup':
                method_type = 'Paid Pickup';
                break;
            case 'delivery':
                method_type = paymentMethod === 'cash' ? 'Pay On Delivery':'Paid Delivery';
                break;
            default:
                method_type = 'Not Confirmed';
        }

        const order = {};
        order.tax = 0;
        order.user_id = selectedCustomer.id;
        order.delivery_fee = 0;
        order.order_status_id = 5;
        order.order_hash = order_hash;
        order.gateway_id = gateway_id;
        order.user = selectedCustomer;
        order.hint = '';
        order.products = [];

        // if(paymentMethod == 'wallet'){
        //     if(onlineMethod == 'wallet' && getGrandTotal() > wallet.wallet_balance){
        //         Alert.alert(Translator.get('insufficient_balance'),Translator.get('insufficient_balance_description'));
        //         return;
        //     }
        // }


        selectedProducts.map((item,index) => {
            let pr_data = null;
                pr_data = {
                    id: '',
                    quantity:item.quantity,
                    market:market.marketData.id,
                    price:  item.discount_price ? item.price - item.discount_price:item.price,
                    discount_price:item.discount_price,
                    product_id:item.id,
                    variation_id:item.variable ? item.variation_id:null,
                    options:[]
                }


            order.products.push(pr_data);
        });

       // console.log(order);return;

        order.pay_method = paymentMethod;

        order.online_method = '';

        order.payment = {
            id:'',
            method:method_type,
            description:'Paid'
        }

        setCreating(true);

        cartService.proceedCheckout(order)
            .then((data) => {
                toast.success('Purchase Added');
                setSelectedProducts([]);
                setSelectedCustomer(null);
                setPhone('');
            })
            .catch((err) => {
            }).finally(() => setCreating(false));
    }

     const getCartSubTotal = () => {
        let subTotal = 0;

        selectedProducts.map((item) => {
            let product_price = item.discount_price ? item.price - item.discount_price:item.price;
            product_price *= item.quantity;
            subTotal += product_price;

        });
        return subTotal;
    }

    const inCart = (i) => {
        let in_cart = null;

        selectedProducts.map((item,index) => {
            if(index === i){
                in_cart = item;
                in_cart.index_id = index;
            }
        });
        return in_cart;
    }

    const increaseQuantity = (index_id) => {

        const in_cart = inCart(index_id);

        if(in_cart && (parseInt(in_cart.available_stock) - in_cart.quantity) <= 0){
                toast.error('Cannot add more than available stock!');
                return;
        }

        const updatedProducts = selectedProducts.map((item,index) => {
            if(index === index_id){
                return {
                    ...item,
                    quantity:item.quantity + 1
                }
            } else {
                return item;
            }
        });
        setSelectedProducts(updatedProducts);
    };

    const decreaseQuantity = (index_id) => {
        const found = selectedProducts[index_id];
        if(found.quantity > 1){
            const updatedProducts = selectedProducts.map((item,index) => {
                if(index === index_id){
                    return {
                        ...item,
                        quantity:item.quantity - 1
                    }
                } else {
                    return item;
                }
            });
            setSelectedProducts(updatedProducts);
        } else {
            setSelectedProducts(selectedProducts.filter((item,index) => index != index_id));
        }
    };

    const removeFromCart = (index_id) => {
        setSelectedProducts(selectedProducts.filter((item,index) => index != index_id));
    }

    const onQuantityChange = (index_id,amount) => {
        const in_cart = inCart(index_id);
        if(in_cart && (parseInt(in_cart.available_stock) - amount) < 0){
            toast.error('Cannot add more than available stock!');
            return;
        }
        const updatedProducts = selectedProducts.map((item,index) => {
            if(index === index_id){
                return {
                    ...item,
                    quantity:amount
                }
            } else {
                return item;
            }
        });
        setSelectedProducts(updatedProducts);
    }

    return (
            <div className="nk-content nk-content-lg nk-content-fluid posContainer">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block invest-block">
                                <div className="row">
                                    <div className="col-lg-7">
                                                <div className="leftCol">
                                                    <div className="invest-field form-group">
                                                        <div className="form-control-group ">
                                                            <AutoCompleteProducts selected_value={chosenValue}/>
                                                        </div>
                                                    </div>

                                                    <div className="card mb-4 productTable">
                                                        <div className="nk-tb-list is-separate">
                                                            <NkTbHead/>
                                                            <NkTbItem onChange={onQuantityChange} removeFromCart={removeFromCart} increase={increaseQuantity} decrease={decreaseQuantity} selectedProducts={selectedProducts}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" col-lg-5">
                                                <div className="card card-bordered ml-lg-4 ml-xl-0 rightCol">
                                                        <div className="px-3 pt-2">
                                                            <label htmlFor="">Customer Details</label>
                                                            <div className="row mb-3">
                                                                <div className="col-md-8">
                                                                    <div className="form-control-group ">

                                                                        <input type="text"
                                                                               className="form-control "
                                                                               id="custom-amount" onChange={(e) => setPhone(e.target.value)} placeholder="Customer Phone"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-control-group ">
                                                                        <button className="btn btn-primary" type={'button'} onClick={findCustomer}>Find Customer</button>
                                                                    </div>
                                                                </div>
                                                                {selectedCustomer && <div className="col-md-12 m-2">
                                                                    <div className="user-card">
                                                                        <div className="user-info"><span
                                                                            className="lead-text">{selectedCustomer.name}</span><span
                                                                            className="sub-text">{selectedCustomer.phone}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        {/*<div className="nk-iv-wg4-sub scrollLayer">*/}
                                                        {/*</div>*/}

                                                        <div className="nk-iv-wg4-sub">
                                                            <ul className="nk-iv-wg4-list">
                                                                <li>
                                                                    <div className="sub-text">Sub Total</div>
                                                                    <div className="lead-text">₹ {getCartSubTotal()}</div>
                                                                </li>
                                                                {/*<li>*/}
                                                                {/*    <div className="sub-text">Conversion*/}
                                                                {/*        Fee <span>(0.5%)</span></div>*/}
                                                                {/*    <div className="lead-text">$ 1.25</div>*/}
                                                                {/*</li>*/}
                                                            </ul>
                                                        </div>

                                                        <div className="nk-iv-wg4-sub">
                                                            <ul className="nk-iv-wg4-list">
                                                                <li>
                                                                    <div className="lead-text">Total</div>
                                                                    <div className="caption-text text-primary">₹ {getCartSubTotal()}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        {/*Choose Payment Method */}
                                                        <div className="invest-field form-group float-left w-100 px-2 mt-2 mb-0">
                                                            <div className="form-label-group">
                                                                <label className="form-label">Choose Payment Method</label>
                                                            </div>
                                                            <div className="invest-amount-group g-2">
                                                                <div className="invest-amount-item">
                                                                    <input type="radio" className="invest-amount-control"
                                                                           name="iv-amount" id="iv-amount-1"/>
                                                                    <label className="invest-amount-label"
                                                                           htmlFor="iv-amount-1">CASH</label>
                                                                </div>
                                                                <div className="invest-amount-item">
                                                                    <input type="radio" className="invest-amount-control"
                                                                           name="iv-amount" id="iv-amount-2"/>
                                                                    <label className="invest-amount-label"
                                                                           htmlFor="iv-amount-2">Spacium Wallet</label>
                                                                </div>
                                                                <div className="invest-amount-item">
                                                                    <input type="radio" className="invest-amount-control"
                                                                           name="iv-amount" id="iv-amount-3"/>
                                                                    <label className="invest-amount-label"
                                                                           htmlFor="iv-amount-3">UPI</label>
                                                                </div>
                                                                <div className="invest-amount-item">
                                                                    <input type="radio" className="invest-amount-control"
                                                                           name="iv-amount" id="iv-amount-4"/>
                                                                    <label className="invest-amount-label"
                                                                           htmlFor="iv-amount-4">Debit Card</label>
                                                                </div>
                                                                <div className="invest-amount-item">
                                                                    <input type="radio" className="invest-amount-control"
                                                                           name="iv-amount" id="iv-amount-5"/>
                                                                    <label className="invest-amount-label"
                                                                           htmlFor="iv-amount-5">Spacium Card</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="nk-iv-wg4-sub text-center bg-lighter">
                                                            <button onClick={createOrder} disabled={!selectedCustomer || selectedProducts.length <= 0 || creating} className="btn btn-lg btn-primary ttu">Confirm &amp; proceed</button>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                </div>
        );
}

export default PointOfSale;
