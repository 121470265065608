import React, {useState, useEffect} from 'react';
import StatisticsColFour from "./components/StatisticsColFour";
import StatisticsColTwo from "./components/StatisticsColTwo";
import PageHeading from "../../components/header/PageHeading";
import {userService} from '../../../services/service-user';
import {marketService} from '../../../services/service-markets';

const Home = () => {
    const [countData, setCountData] = useState('');
    const [orders, setOrders] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [productSales, setProductSales] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState('');
    const [ordersCount, setOrdersCount] = useState({
        orders_count: 0,
        delivered_count: 0,
    });
    useEffect(() => {
        getCounts();
        getDashboardStats();
    }, []);

    const getCounts = () => {
        marketService.getOrdersCounts()
            .then((res) => {
                setOrdersCount({
                    orders_count: res.orders,
                    delivered_count: res.delivered_count
                })
            })
        userService.getCounts()
            .then((res) => {
                setCountData(res);
            });
    }

    const getDashboardStats = () => {
        marketService.getDashboardStats(startDate, endDate)
            .then((res) => {
                setTotalEarnings(res.earnings);
                setProductSales(res.products);
            });
    }
    return (
        <div className="nk-content-inner">
            <div className="nk-content-body">
                <PageHeading name={'Dashboard'}/>
                <div className="nk-block">
                    <div className="row g-gs">
                        <StatisticsColFour ordersCount={ordersCount} counts={countData}/>
                        <StatisticsColTwo
                            applyFilter={getDashboardStats} products={productSales}
                            earnings={totalEarnings} startDate={startDate} endDate={endDate}
                            setStartDate={(date) => setStartDate(date)}
                            setEndDate={(date) => setEndDate(date)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
