import React, {Component} from 'react';
import { Link } from 'react-router-dom';
const statusBadge = (status) => {
    switch(status){
        case 2:
            return (<span className="badge badge-sm badge-dot has-bg badge-danger  d-mb-inline-flex">Cancelled</span>);
            break;
        case 0:
            return (<span className="badge badge-sm badge-dot has-bg badge-info  d-mb-inline-flex">Order Received</span>);
            break;
        case 1:
            return (<span className="badge badge-sm badge-dot has-bg badge-success  d-mb-inline-flex">Processed</span>);
            break;

    }
}

const QuickOrderItem = (props) => {
    return (
        <>
            <div key={props.order.id} className="nk-tb-item">
                <div className="nk-tb-col" style={{width:'100px'}}>
                        <span className="tb-lead">
                        #{props.order.id}
                        </span>
                </div>
                <div className="nk-tb-col tb-col-md">
                    <span className="tb-sub">{props.order.created_at}</span>
                </div>

                <div className="nk-tb-col tb-col-sm" style={{width:'400px'}}>
                    <span className="tb-lead">{props.order.user.phone} - ({props.order.user.name})</span>
                    <span className="tb-sub">{props.order.user.defaultaddress?.address} ({props.order.user.defaultaddress?.complete_address})</span>
                </div>
                <div className="nk-tb-col">
                    {statusBadge(props.order.status)}
                </div>
                <div className="nk-tb-col nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                        {/*{ props.order.order_status.id != 5 && props.order.active && <li>*/}
                        {/*        <a href="javascript:;" onClick={() => props.updateStatus(props.order.id,5)}*/}
                        {/*            className="btn btn-icon btn-trigger btn-tooltip"*/}
                        {/*            title="Mark as Delivered">*/}
                        {/*        <em className="icon ni ni-truck"></em></a></li>}*/}
                        <li>
                            <a className={'btn btn-primary'} href={props.order.media[0].url} target={'_blank'}>
                                View
                            </a>
                        </li>
                        {props.order.status <= 0 && <li>
                            <Link className={'btn btn-success'} onClick={props.processOrder}>
                                Process
                            </Link>
                        </li>}
                        {props.order.status < 2 && <li>
                            <Link className={'btn btn-danger'} onClick={props.cancelOrder}>
                                Cancel
                            </Link>
                        </li>}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default QuickOrderItem;
