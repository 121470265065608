import React, {Component} from 'react';
import { Link } from 'react-router-dom';
const NkTbItem = (props) => {


        return (
            <>
                <div className="nk-tb-item">

                    <div className="nk-tb-col">
                        <img src={props.product.has_media ? props.product.media[0].icon:''} alt="" width="60" height="60" className="product-thumb thumb"/>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                         <span className="title"><Link to={"/products/addProduct/"+props.product.id}>{props.product.name}</Link></span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-lead">Rs. {props.product.price}</span>
                    </div>
                    { !props.product.variable ? <><div className="nk-tb-col">
                        <span className="tb-sub">{props.product.capacity + ' - '+props.product.unit}</span>
                    </div>
                    <div className="nk-tb-col">
                        <span className="tb-sub">{props.product.product_stock ? props.product.product_stock:0}</span>
                    </div></>
                    :<>
                        { props.product.variations.length > 0 && <><div className="nk-tb-col">
                            <span className="tb-sub">{props.product.variations[0].capacity + ' - '+props.product.variations[0].unit}</span>
                        </div>
                            <div className="nk-tb-col">
                                <span className="tb-sub">{props.product.variations[0].product_stock}</span>
                            </div></>}</>
                    }
                    <div className="nk-tb-col tb-col-md">
                        <span className="tb-sub">{props.product.deliverable ? 'Deliverable':'Not Deliverable'}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                        <span className="title">
                            {props.product.category.name}
                        </span>
                    </div>
                    <div className="nk-tb-col nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1 my-n1">
                            <li className="mr-n1">
                                <div className="dropdown">
                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger"
                                       data-toggle="dropdown"><em
                                        className="icon ni ni-more-h"></em></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <ul className="link-list-opt no-bdr">
                                            <li><Link to={"/products/addProduct/"+props.product.id}>
                                                <em className="icon ni ni-edit"></em><span>Edit Product</span>
                                                </Link>
                                            </li>
                                            <li><a onClick={() => props.deleteProduct(props.product.id)}><em className="icon ni ni-trash"></em><span>Remove Product</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default NkTbItem;
