import React, {Component} from 'react';


const StandardProduct = (props) => {
    return (
        <>
            <div className="row g-3 align-center">
                <div className="col-lg-4">
                    <div className="form-group">
                        <label htmlFor="product-price">Price</label>
                        <div className="form-control-wrap">
                            <input type="number" value={props.productData.price}
                                   onChange={(event) => props.changeHandle({...props.productData, price: event.target.value})}
                                   className={"form-control " + (props.getError('price') ? 'error' : '')} id="product-price"
                                   placeholder="Product Price"/>
                            {props.getError('price') && <span className="error">{props.getError('price')}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-group">
                        <label htmlFor="product-discount">Discount</label>
                        <div className="form-control-wrap">
                            <input type="number" value={props.productData.discountPrice} onChange={(event) => props.changeHandle({
                                ...props.productData,
                                discountPrice: event.target.value
                            })} className={"form-control " + (props.getError('discountPrice') ? 'error' : '')}
                                   id="product-discount"
                                   placeholder="Product Discount"/>
                            {props.getError('discount') && <span className="error">{props.getError('discount')}</span>}
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="form-group">
                        <label htmlFor="product-unit"> Product Stock</label>
                        <div className="form-control-wrap">
                            <input type="text" value={props.productData.product_stock}
                                   onChange={(event) => props.changeHandle({...props.productData, product_stock: event.target.value})}
                                   className={"form-control " + (props.getError('product_stock') ? 'error' : '')} id="product-stock"
                                   placeholder="Enter product stock"/>
                            {props.getError('product_stock') && <span className="error">{props.getError('product_stock')}</span>}
                        </div>
                    </div>
                </div>

            </div>
            <div className="row g-3 align-center">

                <div className="col-lg-4">
                    <div className="form-group">
                        <label htmlFor="product-capacity">Capacity</label>
                        <div className="form-control-wrap">
                            <input type="number" value={props.productData.capacity}
                                   onChange={(event) => props.changeHandle({
                                       ...props.productData,
                                       capacity: event.target.value
                                   })}
                                   className={"form-control " + (props.getError('capacity') ? 'error' : '')}
                                   id="product-capacity"
                                   placeholder="Insert Capacity"/>
                            {props.getError('capacity') &&
                            <span className="error">{props.getError('capacity')}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-group">
                        <label htmlFor="product-unit">Unit</label>
                        <div className="form-control-wrap">
                            <input type="text" value={props.productData.unit}
                                   onChange={(event) => props.changeHandle({...props.productData, unit: event.target.value})}
                                   className={"form-control " + (props.getError('unit') ? 'error' : '')} id="product-unit"
                                   placeholder="Enter Unit Of Product"/>
                            {props.getError('unit') && <span className="error">{props.getError('unit')}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-group">
                        <label htmlFor="package_items_count">Number of items in pack</label>
                        <div className="form-control-wrap">
                            <input type="number" value={props.productData.package_items_count}
                                   onChange={(event) => props.changeHandle({
                                       ...props.productData,
                                       package_items_count: event.target.value
                                   })}
                                   className={"form-control " + (props.getError('package_items_count') ? 'error' : '')}
                                   id="package_items_count"
                                   placeholder="How many items in one package?"/>
                            {props.getError('package_items_count') &&
                            <span className="error">{props.getError('package_items_count')}</span>}
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}


export default StandardProduct;
