import React, {useState,useEffect} from 'react';
import Pagination from "react-js-pagination";
import NkTbHead from "./components/NkTbHead";
import NkTbItem from "./components/NkTbItem";
import AddProduct from "./components/AddProduct";
import { Link,useHistory,useLocation} from 'react-router-dom';
import {marketService} from '../../../services/service-markets';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import {initialService} from "../../../services/service-initial";
import Select from "react-select";
const Products = () => {
    const history = useHistory();
    const locChange = useLocation();
    const {market} = useSelector(state => state);
    const [loading,setLoading] = useState(false);
    const [products,setProducts] = useState([]);
    const [totalProducts,setTotalProducts] = useState(0);
    const [brands,setBrands] = useState([]);
    const params = queryString.parse(window.location.search);
    const [state,setState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? parseInt(params.page):1,
        term:params.term && params.term != undefined ? params.term:'',
        status:params.status && params.status != undefined ? params.status:'',
        brand:params.brand && params.brand != undefined ? params.brand:''
    });

    const [tempState,setTempState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? parseInt(params.page):1,
        term:params.term && params.term != undefined ? params.term:'',
        status:params.status && params.status != undefined ? params.status:'',
        brand:params.brand && params.brand != undefined ? params.brand:''
    });
    useEffect(() => {
        getBrands().then(() => {
            getOrders();
        });
    }, []);

    useEffect(() => {
        const params = queryString.parse(locChange.search);
        setState({
            ...state,
            page:params.page && params.page != undefined ? parseInt(params.page):1,
            term:params.term && params.term != undefined ? params.term:'',
            status:params.status && params.status != undefined ? params.status:'',
            brand:params.brand && params.brand != undefined ? params.brand:''
        });
        console.log('state changes',params);
    },[locChange])

    useEffect(() => {
        getOrders();
    }, [state]);

    const applyFilter = () => {

        history.push(`/products?page=1&brand=${tempState.brand?.value}&term=${tempState.term}&status=${tempState.status}`);

        //getOrders(1);
    }

    const getOrders = (pagenum = '') => {
        setLoading(true);
        marketService.marketProducts({page:pagenum ? pagenum:state.page,term:state.term,brand:state.brand,limit:state.limit})
        .then(res => {
            setLoading(false);
            setProducts(res.products);
            if(res.counts){
            setTotalProducts(res.counts);
            }
        },
        err => {
            setLoading(false);
            alert(err.message);
        });
    }

    const handlePageChange = (page) => {
        history.push(`/products?page=${page}&term=${state.term}&brand=${state.brand}&status=${state.status}`);
        setState({...state,page});
    }

    const deleteProduct = (product_id) => {
        marketService.removeProduct(product_id)
        .then((res) => getOrders());
    }


    const nextPage = (page) => {
        setState({
            ...state,
            page:state.page+1
        });
    }

    const prevPage = (page) => {
        if(state.page <= 1){
            return;
        }
        setState({
            ...state,
            page:state.page-1
        });
    }

    const getBrands = async () => {
        await initialService.getBrands()
            .then((res) => {
                let arr = [
                    {
                        label:'View All',
                        value: null,
                    }
                ]
                let brands = res.map((item,index) => {
                    if(params.brand && params.brand != undefined){
                        // setState({
                        //    ...state,
                        //    brand:{
                        //        label:item.name,
                        //        value:item.id
                        //    }
                        // });
                    }
                    return {
                        label:item.name,
                        value:item.id,
                    }

                });


                setBrands(arr.concat(brands));

            });
        return Promise.resolve(true);
    }
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-block-head-sm">
                        <div className="nk-block-between">
                            <div className="nk-block-head-content">
                                <h3 className="nk-block-title page-title">Products</h3>
                            </div>
                            <div className="nk-block-head-content">
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                       data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <div className="form-control-wrap">
                                                    <div className="form-icon form-icon-right">
                                                        <em className="icon ni ni-search"></em>
                                                    </div>
                                                    <input type="text" value={tempState.term} onChange={(event) => setTempState({...tempState,term:event.target.value})} className="form-control" id="default-04"
                                                           placeholder="Search by name"/>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-group">
                                                    <div className="form-control-wrap">
                                                        <div className="form-icon form-icon-right">
                                                            <em className="icon ni ni-search"></em>
                                                        </div>
                                                        <Select value={tempState.brand}
                                                                className={'filter-select selectable '}
                                                                onChange={(event) => {
                                                                    console.log(event);
                                                                    setTempState({
                                                                        ...tempState,
                                                                        brand: event
                                                                    })
                                                                }} options={brands}/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                                <button onClick={applyFilter} data-target="addProduct"
                                                   className="toggle btn btn-icon btn-primary d-md-none"><em
                                                    className="icon ni ni-search"></em></button>
                                                <button onClick={applyFilter} data-target="addProduct"
                                                   className="toggle btn btn-primary d-none d-md-inline-flex"><em
                                                    className="icon ni ni-search"></em><span>Filter</span></button>
                                            </li>
                                            <li className="nk-block-tools-opt">
                                            <div class="drodown"><a href="#" class="dropdown-toggle btn btn-icon btn-primary" data-toggle="dropdown"
                                                    aria-expanded="false"><em class="icon ni ni-plus"></em></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <ul className="link-list-opt no-bdr">
                                                        <li><Link to="/products/addProduct"><span>Add Product</span></Link></li>
                                                        <li><Link to="/inventory"><span>Copy from Inventory</span></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="nk-tb-list is-separate mb-3">
                            <NkTbHead/>
                            {products.length > 0 && products.map((value,index) => <NkTbItem deleteProduct={deleteProduct} product={value}/>)}


                        </div>
                        {products.length <= 0 && !loading && <div className={'text-center'}>No Products Found</div>}
                        {loading && <div className={'text-center'}> <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                        {!loading && totalProducts > 0 && <div>
                            <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="« First Page"
                            lastPageText="Last Page »"
                            nextPageText="Next ⟩"
                            prevPageText="⟨ Prev"
                            activePage={state.page}
                            itemsCountPerPage={state.limit}
                            totalItemsCount={totalProducts}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            />
                        </div>}
                    </div>
                    <AddProduct/>
                </div>
            </div>
        );
}

export default Products;
