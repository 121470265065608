import React, {useEffect, useState} from 'react'
import {Route,useHistory} from 'react-router-dom';
import Header from "./header/Header";
import PointOfSale from "../main/pos/PointOfSale";
import Invoice from "../main/invoice/Invoice";
import {useDispatch, useSelector} from "react-redux";
import {marketService} from "../../services/service-markets";
import {setMarketState} from "../../store/actions/market.actions";
const PosRoute = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {user,market} = useSelector((state) => state);
    const [loading,setLoading] = useState(true);
    useEffect(() => {
        if(!user.currentUser?.id){
            history.push('login');
        } else {
            initialData();
        }
    }, [])


    const initialData = () => {
        marketService.myMarket({api_token:user.currentUser.apiToken}).then((data) => {
            setLoading(false);
            if(data){
                dispatch(setMarketState(data));
            } else {
                //window.location.href = 'add-store';
            }

        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }
    return (
        <Route render={props => (
            <>
                <div className="nk-main ">
                    <div className="nk-wrap ">
                        <Header/>
                        <div className="nk-content nk-content-lg nk-content-fluid mt-0">
                            <Route exact path="/pointOfSale" component={PointOfSale}/>
                            <Route exact path="/invoice" component={Invoice}/>
                        </div>
                    </div>
                </div>
            </>
        )}
    />
)};

export default PosRoute;
