import { API_BASE_URL_ROOT,API_BASE_URL,BASE_URL } from '../helper/globals';
import axios from 'axios';
import {store} from "../store";
const state = () => {
    return store.getState();
}

async function proceedCheckout(order){
    return new Promise(function(resolve, reject) {
        order.api_token = state().user.currentUser.apiToken;
        axios.post(API_BASE_URL + 'orders', order)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject({error:error.response});
            })
    });
};


async function syncCart(products){
    return new Promise(function(resolve, reject) {

        axios.post(API_BASE_URL + 'products/stock', {
            api_token:state().user.currentUser.apiToken,
            products
        })
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject({error});
            })
    });
};


export const cartService = {
    proceedCheckout,
    syncCart
};
