import firebase from 'firebase/app';
import 'firebase/messaging';
var firebaseConfig = {
    apiKey: "AIzaSyC5BbP0QUEGjTy8aA6CS7lcH7cQ7w3MdhQ",
    authDomain: "spacium-store.firebaseapp.com",
    databaseURL: "https://spacium-store.firebaseio.com",
    projectId: "spacium-store",
    storageBucket: "spacium-store.appspot.com",
    messagingSenderId: "493514191951",
    appId: "1:493514191951:web:cc653cfe08abddffd4a63a",
    measurementId: "G-8RJNEEZ0ZW"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
    return messaging.getToken({vapidKey: 'BK6qAzgVEhagLy-Vqm1xrQqgG2ZkzIm4lHa6JUj9TVznzCRZvraU8kUVu2V6LWZ92cRqjC4mjsTbmfTu4sM_gqw'}).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
           // console.log(payload);
            resolve(payload);
        });
    });
