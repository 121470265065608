import React,{useEffect,useState} from 'react'
import {Route,useHistory} from 'react-router-dom';
import Home from "../main/home/Home";
import Loader from "../initial/loader";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import Orders from "../main/orders/Orders";
import QuickOrders from "../main/orders/QuickOrders";
import Products from "../main/products/Products";
import Inventory from "../main/inventory/Inventory";
import Customer from "../main/customer/Customer";
import Profile from "../main/profile/Profile";
import Market from "../main/profile/Market";
import CreateStore from "../auth/createStore";
import AddProduct from '../main/store/AddProduct';
import OrderDetail from "../main/orders/OrderDetail";
import {useSelector,useDispatch} from 'react-redux';
import {marketService} from '../../services/service-markets';
import {setMarketState} from '../../store/actions/market.actions';
import {getToken,onMessageListener} from "../../firebase";
import {toast} from "react-toastify";
import firebase from "firebase";
import {userService} from "../../services/service-user";
const messaging = firebase.messaging();


const MainRoute = ({component: Component}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {user,market} = useSelector((state) => state);
    const [loading,setLoading] = useState(true);
    const [isTokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        if(!user.currentUser?.id){
            history.push('login');
        } else {
            initialData();
        }
        getToken(setToken);
    }, [])

    useEffect(() => {
        messaging.onMessage((payload) => {
            toast.success(payload.notification.body);
        });
    },[])

    const setToken = (token) => {
        if(!token){
            return;
        }
        const device_id = localStorage.getItem('login_time');
        userService.updateToken(device_id,token)
            .then((res) => console.log(res));
    }

    const initialData = () => {
        marketService.myMarket({api_token:user.currentUser.apiToken}).then((data) => {
               // setLoading(false);
                if(data){
                        dispatch(setMarketState(data));
                        loadDrivers(data.id);
                }

        }).catch((err) => {
            setLoading(false);
        })
    }

    const loadDrivers = (market_id) => {
        marketService.myMarketDrivers(market_id).then((data) => {
            // setLoading(false);
            if(data){
                dispatch(setMarketState(data));
                loadDrivers(data.id);
            }

        }).catch((err) => {
            setLoading(false);
        })
    }

    return (
        <Route
            render={props => (
                <>
                {loading ?
                <Loader/>:
                 <div className="nk-main ">
                     {/*{market.marketData && market.marketData.active && <Sidebar/>}*/}
                     <div className="nk-wrap ">
                         <Header/>
                         <div className="nk-content nk-content-lg nk-content-fluid mt-0 pt-0">

                                 {market.marketData && market.marketData.active ?
                                     <>
                                         <Route exact path="/" component={Home}/>
                                         <Route exact path="/orders" component={Orders}/>
                                         <Route exact path="/quick-orders" component={QuickOrders}/>
                                         <Route exact path="/orderDetail/:id" component={OrderDetail}/>
                                         <Route exact path="/products" component={Products}/>
                                         <Route exact path="/inventory" component={Inventory}/>
                                         <Route exact path="/customer" component={Customer}/>
                                         <Route exact path="/products/addProduct" component={AddProduct}/>
                                         <Route exact path="/products/addProduct/:id" component={AddProduct}/>
                                         <Route exact path="/profile" component={Profile}/>
                                         <Route exact path="/market" component={Market}/>
                                     </>
                                     :
                                    <>
                                        <Route exact path="/" component={CreateStore}/>
                                        <Route exact path="/profile" component={Profile}/>
                                    </>
                                 }
                         </div>
                     </div>
                 </div>
                }
                </>
            )}
        />
    )
};

export default MainRoute;
