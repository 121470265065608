import React, {Component} from 'react';

const StatisticsColFour = (props) => {
        return (
            <>
                <div className="col-xxl-4 col-sm-4">
                    <div className="card">
                        <div className="nk-ecwg nk-ecwg6">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h6 className="title">Total Orders</h6>
                                    </div>
                                </div>
                                <div className="data">
                                    <div className="data-group">
                                            <div className="amount">{props.ordersCount.delivered_count}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-sm-4">
                    <div className="card">
                        <div className="nk-ecwg nk-ecwg6">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h6 className="title">Pending Orders</h6>
                                    </div>
                                </div>
                                <div className="data">
                                    <div className="data-group">
                                        <div className="amount">{props.ordersCount.orders_count}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-sm-4">
                    <div className="card">
                        <div className="nk-ecwg nk-ecwg6">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h6 className="title">Revenue</h6>
                                    </div>
                                </div>
                                <div className="data">
                                    <div className="data-group">
                                     <div className="amount">Rs. {props.counts ? props.counts.earning.value:0}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
}

export default StatisticsColFour;
