import React, { useState, useRef, useCallback } from "react";
import { compose, withProps } from "recompose"
import { GoogleMap, useJsApiLoader,Polygon,DrawingManager,Marker} from '@react-google-maps/api';
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import ReactGoogleMapLoader from "react-google-maps-loader";
const MAP_KEY = 'AIzaSyD2vSMnKnPqJ2Fefy_8cU_V2O7IeDDRAfc';
const libraries = ['places','drawing','geometry'];
const MapPolygonContainer = (props) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: MAP_KEY,
        libraries
    });
    const [state,setState] = useState({
        showingInfoWindow:false,
        activeMarker:{},
        selectedPlace:{},
    });
    const [polyComplete,setPolyComplete] = useState(props.path ? true : false);
    const mapContainerStyle = {
        height: "600px",
    }
    const [placeVal,setPlaceVal] = useState(props.address ?? '');
    const [position,setPosition] = useState({
        lat:props.latitude != undefined ? parseFloat(props.latitude):30.7353912,
        lng:props.longitude != undefined ? parseFloat(props.longitude):76.7695381,
    });
    //const center = { lat: 30.7046, lng: 76.7179 }
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);
    const [paths,setPaths] = useState(props.path ?? []);

    const options = {
        fillColor: "black",
        fillOpacity: 0.3,
        strokeColor: "black",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: true,
        editable: true,
        geodesic: false,
        zIndex: 1
    }

    const handleNoResult = () => {

    }

    const onLocationChange = (place) => {
        if(place && place.geometry != undefined) {
            setPosition(place.geometry.location);
            setPlaceVal(place.formatted_address);
            props.handleLocation({
                place_id:place.place_id,
                address:place.formatted_address,
                latitude:place.geometry.location.lat(),
                longitude:place.geometry.location.lng()
            });
        }
    }

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            props.onAreaChange(nextPath);
            console.log(nextPath);
            setPaths(nextPath);
        }
    }, [setPaths]);

    const onLoad = useCallback(
        polygon => {
                    polygonRef.current = polygon;
                    //console.log('polygon set',polygonRef.current);
                    if(polygon.current) {
                        const path = polygon.getPath();
                        listenersRef.current.push(
                            path.addListener("set_at", onEdit),
                            path.addListener("insert_at", onEdit),
                            path.addListener("remove_at", onEdit)
                        );

                    }
        },
        [onEdit]
    );

    const onPolygonComplete = (data) => {
        //console.log(data);
       polygonRef.current = data;
        const path = data.getPath();
        var pathArray = [];
        let polygonArray = [];
        for (var i = 0; i < path.length; i++) {
            pathArray = {lat:path.getAt(i).lat(), lng:path.getAt(i).lng()};

            polygonArray.push(pathArray);
        }
        props.onAreaChange(polygonArray);
        setPaths(polygonArray);
        data.setMap(null);
        setPolyComplete(true);
    }

    const onMapClicked = (pp) => {
        if (state.showingInfoWindow) {
            setState({...state,
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };
    const onMarkerClick = (pp, marker, e) =>{
        setState({...state,
            selectedPlace: pp,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    const onPolygonChange = (polyref) => {
        const nextPath = polygonRef.current
            .getPath()
            .getArray()
            .map(latLng => {
                return { lat: latLng.lat(), lng: latLng.lng() };
            });
        console.log(nextPath);
    }


    const onMarkerDragEnd = (mapProps,maps,coords) => {
        const { latLng } = coords;
        const {google} = mapProps;
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({
            latLng
        }, function(responses) {
            if (responses && responses.length > 0) {
                onLocationChange(responses[0]);
            }
        });
    }

    const renderMap = () => {
        return (
            <div className={'row'}>
                <div className={'col-md-8'}>
                    <ReactGoogleMapLoader
                    params={{
                        key: MAP_KEY,
                        libraries: "places,geocode,drawing",
                    }}
                    render={googleMaps =>
                        googleMaps && (
                            <ReactGooglePlacesSuggest
                                googleMaps={googleMaps}
                                autocompletionRequest={{
                                    input: placeVal,

                                }}
                                // Optional props
                                displayPoweredByGoogle={false}
                                onNoResult={handleNoResult}
                                onSelectSuggest={onLocationChange}
                                onStatusUpdate={onLocationChange}
                                textNoResults="No results" // null or "" if you want to disable the no results item
                                customRender={prediction => (
                                    <div className="customWrapper">
                                        {prediction
                                            ? prediction.description
                                            : "My custom no results text"}
                                    </div>
                                )}
                            >
                                <input
                                    type="text"
                                    value={placeVal}
                                    className={'form-control'}
                                    placeholder="Search a location"
                                    onChange={(event) => setPlaceVal(event.target.value)}
                                />
                            </ReactGooglePlacesSuggest>
                        )
                    }
                />
                </div>
                <div className={'col-md-4'}>
                    {
                        polyComplete &&
                            <button
                                onClick={() => {
                                    setPaths([]);
                                    setPolyComplete(false);
                                }}
                                className={'btn btn-danger'}>Clear Area</button>
                    }
                </div>
                <div className={'col-md-12'}>
                    <GoogleMap
                        id="marker-example"
                        mapContainerStyle={mapContainerStyle}
                        zoom={14}
                        center={position}
                    >
                        <Marker
                            onClick={onMarkerClick}
                            draggable={true}
                            position={position}
                            onDragend={(t, map, coord) => onMarkerDragEnd(t,map,coord)}
                        />

                         <Polygon
                            onLoad={onLoad}
                            paths={paths}
                            onDragEnd={onEdit}
                            onDragEnd={onEdit}
                            options={options}
                            onMouseUp={onEdit}
                        />

                        {!polyComplete &&
                        <DrawingManager
                            drawingMode={polyComplete ? null : 'polygon'}
                            onLoad={onLoad}
                            onPolygonComplete={onPolygonComplete}
                        />
                        }
                    </GoogleMap>
                </div>
            </div>
        )
    }
    if (loadError) {
        return (
            <div>Map cannot be loaded right now, sorry.</div>
        )
    }
    return isLoaded ?
        renderMap()
        :
        <>Loading...</>
}

export default MapPolygonContainer;
