import React, {Component} from 'react';


const VariableProduct = (props) => {

    const handleChange = (key,value,index) => {

        let variations = props.productData.variations.map((va,k) => {
            if(k === index){
                return {
                    ...va,
                    [key]:value
                }
            }
            return va;
        });
        props.changeHandle({
            ...props.productData,
            variations:variations
        });
    }

    return (
        <>
            {props.productData.variations.map((variation,index) => {
                return <div className="card bg-dark-dim p-2 mt-2">
                    <h5 className="heading">Variation #{index+1}</h5>
                    <div className="row g-3 align-center">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="product-price">Price</label>
                                <div className="form-control-wrap">
                                    <input type="number" value={variation.price}
                                           onChange={(event) => handleChange('price',event.target.value,index)}
                                           className={"form-control "} id="product-price"
                                           placeholder="Product Variation Price"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="product-discount">Discount</label>
                                <div className="form-control-wrap">
                                    <input type="number" value={variation.discount_price} onChange={(event) => handleChange('discount_price',event.target.value,index)
                                    }
                                           className={"form-control "}
                                           id="product-discount"
                                           placeholder="Product Variation Discount Price"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="product_stock">Product Variation Stock</label>
                                <div className="form-control-wrap">
                                    <input type="number" value={variation.product_stock} onChange={(event) => handleChange('product_stock',event.target.value,index)
                                    }
                                           className={"form-control "}
                                           id="product_stock"
                                           placeholder="Product Variation Stock"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 mt-2 align-center">
                        <div className="col-lg-4 mt-2">
                            <div className="form-group">
                                <label htmlFor="product-capacity">Capacity</label>
                                <div className="form-control-wrap">
                                    <input type="number" value={variation.capacity}
                                           onChange={(event) => handleChange('capacity',event.target.value,index)}
                                           className={"form-control " + (props.getError('capacity') ? 'error' : '')}
                                           id="product-capacity"
                                           placeholder="Insert Capacity"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="form-group">
                                <label htmlFor="product-unit">Unit</label>
                                <div className="form-control-wrap">
                                    <input type="text" value={variation.unit}
                                           onChange={(event) => handleChange('unit',event.target.value,index)}
                                           className={"form-control "} id="product-unit"
                                           placeholder="Enter Unit Of Product"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="form-group">
                                <label htmlFor="package_items_count">Number Items in pack</label>
                                <div className="form-control-wrap">
                                    <input type="number" value={variation.items}
                                           onChange={(event) => handleChange('items',event.target.value,index)}
                                           className={"form-control "}
                                           id="package_items_count"
                                           placeholder="How many items in one package?"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.productData.variations.length > 1 && <div className="col-lg-12 mt-2">
                            <button onClick={() => {props.removeVariation(index) }} className="btn btn-danger float-right">Remove</button>
                    </div>}
                </div>
                })
            }
        <div className="row p-2">
            <div className="col-lg-12">
                <button onClick={() => {props.addVariation(); return false;}} className="btn btn-success float-right">Add Variation</button>
            </div>
        </div>
        </>
    );
}


export default VariableProduct;
