import React, {useEffect,useState} from 'react';
import AsideCard from './components/AsideCard';
import {useSelector,useDispatch} from 'react-redux';
import {marketService} from '../../../services/service-markets';
import {initialService} from '../../../services/service-initial';
import {mediaService} from '../../../services/service-media';
import {setLoginState} from '../../../store/actions/user.actions';
import MapContainer from '../../components/common/MapContainer';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Imageselector from '../../components/common/Imageselector';
import {toast } from 'react-toastify';
import MapPolygonContainer from "../../components/common/MapPolygonContainer";
const Profile = () => {
        const animatedComponents = makeAnimated();
        const {user,market} = useSelector((state) => state);
        const [marketFields,setMarketFields] = useState([]);
        const [errors,setErrors] = useState({});
        const [fields,setFields] = useState([]);
        const [marketData,setMarketDATA] = useState('');
        const [choosenImage,setChoosenImage] = useState('');
        const dispatch = useDispatch();
        const [saving,setSaving] = useState(false);

        useEffect(() => {
            getMarketData();
        },[]);

        const getFields = () => {
            initialService.getFieldsData()
            .then((res) => {
                console.log(res);
                setFields(res);
            });
        }

        const getMarketData = () => {
            marketService.myMarket()
            .then((res) => {
               setMarketDATA(res);
               setMarketFields(res.fields?.map((item) => {
                    return {value:item.id,label:item.name};
               }));
               getFields();
            });
        }

        const validateForm = () => {
            let isValid = true;
            let err = [];
            if(marketData.name == ''){
                err.name = 'Market name is required!';
                isValid = false;
            }

            setErrors(err);
            return isValid;
        }

        const getError = (name) => {
            return errors[name] !== undefined && errors[name] != '' ? errors[name]:false;
        }

        const saveUser = () => {
            if(!validateForm()){
                return;
            }
            setSaving(true);
            const formData = {
                name:marketData.name,
                fields:marketFields.map((item) => {
                    return item.value
                }),
                phone:marketData.phone,
                mobile:marketData.mobile,
                delivery_range:marketData.delivery_range,
                delivery_fee:marketData.delivery_fee,
                minimum_order:marketData.minimum_order,
                description:marketData.description,
                information:marketData.information,
                address:marketData.address,
                latitude:marketData.latitude,
                longitude:marketData.longitude,
                available_for_delivery:marketData.available_for_delivery,
                active:marketData.active,
                area:marketData.area,
                gst:marketData.gst
            };


            if(choosenImage){
                formData.image = choosenImage;
            }
            marketService.updateMarket(formData,marketData.id)
            .then((res) =>{
                toast.success('Store information updated')
            })
            .catch((err) => console.log(err))
            .then(() => setSaving(false));
        }

        const onImageChange = (event) => {
            if(event.target.files.length <= 0){
              return;
            }
            mediaService.uploadFile(event.target.files[0])
            .then((res) => setChoosenImage(res.uuid));
        }

        const removeImage = () => {
            setChoosenImage('');
        }

        const handleLocationChange = (data) => {
            setMarketDATA({...marketData,
                address:data.address,
                latitude:data.latitude,
                longitude:data.longitude})
        }

        const onAreaChange = (data) => {
            setMarketDATA({...marketData,
                area:data
            })
        }

        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block">
                        <div className="card">
                            <div className="card-aside-wrap">
                                <div className="card-inner card-inner-lg">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="personal">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">Store Information</h4>
                                                        <div className="nk-block-des">
                                                            <p>Provide Your store information</p>
                                                        </div>
                                                    </div>
                                                    <div className="nk-block-head-content align-self-start ">
                                                        {marketData.prime && <div className="prime-member">
                                                            <img className="logo-dark logo-img logo-img-lg" src="../../../../assets/images/prime.png"
                                                                 srcSet="../../../../assets/images/prime.png" alt="logo"/>
                                                        </div>}
                                                        <a href="#" className="toggle btn btn-icon d-lg-none  btn-trigger mt-n1"
                                                            data-target="userAside"><em className="icon ni ni-menu-alt-r"></em></a>
                                                    </div>
                                                </div>
                                            </div>
                                            {marketData && <div className="nk-block">
                                                <div className="nk-data">
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Name</label>
                                                                <input type="text" value={marketData.name} onChange={(event) => setMarketDATA({...marketData,name:event.target.value})} className={"form-control " + (getError('name') ? 'error':'')}  placeholder="Add your name..."/>
                                                                {getError('name') && <span className="error">{getError('name')}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Fields & Domains</label>
                                                                {fields.length > 0 && marketData && <Select
                                                                    closeMenuOnSelect={false}
                                                                    onChange={(item) => setMarketFields(item)}
                                                                    components={animatedComponents}
                                                                    defaultValue={marketFields}
                                                                    isMulti
                                                                    options={fields.map((item) => {
                                                                        return {value:item.id,label:item.name}
                                                                    })}
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Phone Number (landline)</label>
                                                                <input type="text" value={marketData.phone} onChange={(event) => setMarketDATA({...marketData,phone:event.target.value})} className="form-control" placeholder="Market phone number"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Mobile Number</label>
                                                                <input type="text" value={marketData.mobile} onChange={(event) => setMarketDATA({...marketData,mobile:event.target.value})} className="form-control" placeholder="Market mobile number"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>GST NUMBER</label>
                                                                <input type="text" value={marketData.gst} onChange={(event) => setMarketDATA({...marketData,gst:event.target.value})} className="form-control" placeholder="GST Number"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Delivery Fee</label>
                                                                <input type="number" value={marketData.delivery_fee} onChange={(event) => setMarketDATA({...marketData,delivery_fee:event.target.value})} className="form-control" placeholder="Delivery fee"/>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Minimum Delivery Amount</label>
                                                                <input type="number" value={marketData.minimum_order} onChange={(event) => setMarketDATA({...marketData,minimum_order:event.target.value})} className="form-control" placeholder="Minimum order amount for delivery"/>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Area</label>
                                                                <input type="hidden" name="add" className="form-control error"/>
                                                                {getError('address') && <span className="error">{getError('address')}</span>}
                                                                <MapPolygonContainer
                                                                    path={marketData.area}
                                                                    onAreaChange={onAreaChange}
                                                                    handleLocation={handleLocationChange}
                                                                    address={marketData.address}
                                                                    latitude={marketData.latitude}
                                                                    longitude={marketData.longitude}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Description</label>
                                                                <textarea type="number" value={marketData.description} onChange={(event) => setMarketDATA({...marketData,description:event.target.value})} className="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Information</label>
                                                                <textarea type="number" value={marketData.information} onChange={(event) => setMarketDATA({...marketData,information:event.target.value})} className="form-control"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <Imageselector imageUrl={marketData.has_media ? marketData.media[0].url:''} onChange={onImageChange} removeImage={removeImage}/>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Market Delivering?</label><br/>
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" checked={marketData.available_for_delivery} onChange={(event) => setMarketDATA({...marketData,available_for_delivery:!marketData.available_for_delivery})} className="custom-control-input"
                                                                        name="reg-public" id="prod-deliverable"/>
                                                                    <label className="custom-control-label"
                                                                        htmlFor="prod-deliverable">{marketData.available_for_delivery ? 'Delivering':'Not Delivering'}</label>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label>Market Status</label><br/>
                                                                <div className="custom-control custom-switch">
                                                                    <input type="checkbox" checked={marketData.active} onChange={(event) => setMarketDATA({...marketData,active:!marketData.active})} className="custom-control-input"
                                                                        name="reg-public" id="market-status"/>
                                                                    <label className="custom-control-label"
                                                                        htmlFor="market-status">{marketData.active ? 'Open':'Closed'}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row g-3">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <button type="button" disabled={saving} onClick={saveUser} className="btn btn-success">{saving ? 'Saving...':'Save'}</button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>}
                                        </div>

                                    </div>
                                </div>

                                <AsideCard tab="market"/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Profile;
