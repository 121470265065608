import axios from 'axios';
import { BASE_URL,API_BASE_URL } from '../helper/globals';
import {store} from '../store';

const state = () => {
    return store.getState();
}
async function findCustomer(phone){
    return new Promise((resolve, reject) => { // don't forget to use dispatch here!
        axios.get(API_BASE_URL + 'pos/get-customer', {params:{
            phone,
                api_token:state().user.currentUser.apiToken
            }})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:'Something wrong'});
                }
            })
            .catch((err) => {
                reject({error:err});
            });
    });
};


export const posService = {
    findCustomer
};
