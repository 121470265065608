import { API_BASE_URL,API_BASE_URL_ROOT,BASE_URL } from '../helper/globals';
import {store} from '../store';
import axios from 'axios';

const state = () => {
    return store.getState();
}

async function searchProducts(term){
    return new Promise(function(resolve, reject) {

        axios.get(API_BASE_URL + 'products-search', { 'params':{
                api_token:state().user.currentUser.apiToken,
                term,
                limit:10,
            }})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};



async function myMarket(){
    return new Promise(function(resolve, reject) {

        const api_token = state().user.currentUser.apiToken;

        return fetch(API_BASE_URL + 'markets?api_token='+api_token+'&with=fields;drivers&orderBy=id&sortedBy=desc', {
            method: 'GET',
            headers: {  // these could be different for your API call
            Accept: 'application/json',
            'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.success) { // response success checking logic could differ
                resolve(json.data);
            } else {
                reject({error:json.message});
            }
        })
        .catch((err) => {
            reject({error:err});
        });
    });
};

async function getProduct(product_id){
    return new Promise(function(resolve, reject) {

        axios.get(API_BASE_URL + 'products/'+product_id, { 'params':{
            api_token:state().user.currentUser.apiToken,
            with:'subcategory;category;brand'
        }})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};


async function getOrdersCounts(){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:state().user.currentUser.apiToken,
        };
        axios.get(BASE_URL + 'api/manager/orders-count', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:'Something wrong'});
                }
            })
            .catch((error) => {
               // console.log(error.response);
                reject({error});
            })
    });
};

async function getActiveOrders(){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:state().user.currentUser.apiToken,
        };
        axios.get(BASE_URL + 'api/manager/active-orders', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:'Something wrong'});
                }
            })
            .catch((error) => {
                // console.log(error.response);
                reject({error});
            })
    });
};

async function marketProducts({page,term,limit,brand}){
    const pageNum = page && page != undefined && page != '' ? page:1;
    const per_page = limit ?? 10;
    const api_token = state().user.currentUser.apiToken;
    const market_id = state().market.marketData.id;
    const offset = (pageNum - 1) * per_page;

    const query_params = {
        api_token,
        offset,
        limit:per_page,
        orderBy:'id',
        sortedBy:'desc',
        with:'category',
        market_id,
        search:'',
        searchFields:'',
        searchJoin:'and'

    }

    if(term){
        query_params.search = query_params.search +'name:'+term+';';
        query_params.searchFields = query_params.searchFields+'name:like;';
    }

    if(brand && brand != 'null' && brand != 'undefined'){
        query_params.search = query_params.search+'brand_id:'+brand+';';
        query_params.searchFields = query_params.searchFields+'brand_id:=;';
    }

    return new Promise(function(resolve, reject) {
        axios.get(BASE_URL + 'api/manager/products/admin', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
  };
async function getOrders({page,limit,term,status}){
    return new Promise(function(resolve, reject) {
            const offset = page && page != 1 ? (page - 1) * limit:0;
            const query_params = {
                api_token:state().user.currentUser.apiToken,
                limit:limit ? limit:30,
                offset,
                term,
                status
            };

            axios.get(BASE_URL + 'api/manager/orders', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
  };
  async function getOrderDetails(order_id){
    return new Promise(function(resolve, reject) {
            const query_params = {
                api_token:state().user.currentUser.apiToken,
                with:'driver;user;productOrders;productOrders.product;productOrders.options;orderStatus;deliveryAddress;payment',
            };
            console.log(query_params);
            axios.get(BASE_URL + 'api/orders/'+order_id, { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
  };

  async function updateOrderDetails({order_id,status,driverSelected}){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:state().user.currentUser.apiToken,
            id: order_id,
            user_id:state().user.currentUser.id,
            driver_id:driverSelected,
            order_status_id:status
        };
        console.log(query_params);
        axios.put(BASE_URL + 'api/manager/orders/'+order_id, query_params)
        .then((response) => {
            const json = response.data;
            if (json.success) { // response success checking logic could differ
                resolve(json.data);
            } else {
                reject({error:json.message});
            }
        })
        .catch((error) => {
            console.log(error);
            reject({error});
        })
    });
}

async function cancelOrder(order_id){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:state().user.currentUser.apiToken,
            id: order_id,
            active:false
        };
        axios.put(BASE_URL + 'api/orders/'+order_id, query_params)
        .then((response) => {
            const json = response.data;
            if (json.success) { // response success checking logic could differ
                resolve(json.data);
            } else {
                reject({error:json.message});
            }
        })
        .catch((error) => {
            console.log(error);
            reject({error});
        })
    });
}

async function removeProduct(product_id){
    return new Promise(function(resolve, reject) {
        axios.delete(BASE_URL + 'api/manager/products/'+product_id,{
            params:{
                api_token:state().user.currentUser.apiToken
            }
        })
        .then((response) => {
            const json = response.data;
            if (json.success) { // response success checking logic could differ
                resolve(json.data);
            } else {
                reject({error:json.message});
            }
        })
        .catch((error) => {
            console.log(error);
            reject({error});
        })
    });
}


async function marketSwitch({api_token,market_id,closed}){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:api_token,
            id: market_id,
            closed:closed
        };
        axios.put(API_BASE_URL + 'markets/'+market_id, query_params)
        .then((response) => {
            const json = response.data;
            console.log(json);
            if (json.success) { // response success checking logic could differ
                resolve(json.data);
            } else {
                reject({error:json.message});
            }
        })
        .catch((error) => {
            console.log(error);
            reject({error});
        })
    });
}


async function saveProduct(productData,product_id){
    return new Promise(function(resolve, reject) {
            productData.api_token = state().user.currentUser.apiToken;
            productData.market_id = state().market.marketData.id;

            let call = '';

            if(product_id && product_id != undefined){
                call = axios.put(API_BASE_URL_ROOT + 'manager/products/'+product_id+'?api_token='+state().user.currentUser.apiToken, productData)
            } else {
                call = axios.post(API_BASE_URL_ROOT + 'manager/products', productData)
            }

            call.then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            })
    });
};

async function updateMarket(marketData,product_id){
    return new Promise(function(resolve, reject) {
            marketData.api_token = state().user.currentUser.apiToken;

            axios.put(API_BASE_URL_ROOT + 'manager/markets/'+state().market.marketData.id, marketData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            })
    });
};

async function addMarket(marketData){
    return new Promise(function(resolve, reject) {
            marketData.api_token = state().user.currentUser.apiToken;

            axios.post(API_BASE_URL_ROOT + 'manager/markets', marketData)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                reject(error.response.data);
            })
    });
};

async function updateQuickOrder({order_id,status}){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:state().user.currentUser.apiToken,
            id: order_id,
            status:status
        };
        console.log(query_params);
        axios.put(BASE_URL + 'api/manager/quick-orders/'+order_id, query_params)
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function getQuickOrders({page,limit,term,status,device}){
    return new Promise(function(resolve, reject) {
        const offset = page && page != 1 ? (page - 1) * limit:0;
        const query_params = {
            api_token:state().user.currentUser.apiToken,
            limit:limit ? limit:30,
            offset,
            term,
            status,
            device,
            with:'user;user.defaultaddress',
            orderBy:'id',
            sortedBy:'desc',
        };

        axios.get(BASE_URL + 'api/manager/quick-orders', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

async function getDashboardStats(from_date = '',to_date = ''){
    return new Promise(function(resolve, reject) {
        const query_params = {
            api_token:state().user.currentUser.apiToken,
            from_date,
            to_date
        };

        axios.get(BASE_URL + 'api/manager/dashboard', { 'params':query_params})
            .then((response) => {
                const json = response.data;
                if (json.success) { // response success checking logic could differ
                    resolve(json.data);
                } else {
                    reject({error:json.message});
                }
            })
            .catch((error) => {
                console.log(error);
                reject({error});
            })
    });
};

export const marketService = {
    myMarket,
    marketProducts,
    getOrders,
    getOrderDetails,
    updateOrderDetails,
    cancelOrder,
    marketSwitch,
    saveProduct,
    getProduct,
    removeProduct,
    updateMarket,
    addMarket,
    getOrdersCounts,
    searchProducts,
    getActiveOrders,
    updateQuickOrder,
    getQuickOrders,
    getDashboardStats
};
