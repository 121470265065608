import React from 'react';
const Snippet = (props) =>{
    return (
            <div className="center">
                <div className="input-group">
                      <span className="input-group-btn">
                          <button type="button" onClick={props.decrease} className="btn btn-danger btn-number" data-type="minus" data-field="quant[2]">
                               <em className="icon ni ni-minus">
                               </em>
                          </button>
                      </span>
                      <span
                          className="flex-0 mx-2 justify-center align-items-center"
                          style={{width:'50px', 'border-radius': 5,}}
                      >
                          <input type="number" className={'form-control'} value={parseInt(props.quantity)} onChange={props.onChange}/>
                      </span>
                      <span className="input-group-btn">
                          <button type="button" onClick={props.increase}  className="btn btn-success btn-number" data-type="plus" data-field="quant[2]">
                              <em className="icon ni ni-plus">
                              </em>
                          </button>
                      </span>
                </div>
            </div>
    );
}
export default Snippet;
