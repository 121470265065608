import React, {useState,useEffect} from 'react';
import PageHeading from "../../components/header/PageHeading";
import Imageselector from "../../components/common/Imageselector";
import {initialService} from '../../../services/service-initial';
import {marketService} from '../../../services/service-markets';
import {mediaService} from '../../../services/service-media';
import StandardProduct from './components/StandardProduct';
import { Link,useHistory} from 'react-router-dom';
import Select from 'react-select'
import {toast } from 'react-toastify';
import VariableProduct from "./components/VariableProduct";
const AddProduct = (props) => {
        const history = useHistory();
        const product_id = props.match.params.id;
        const [categories,setCategories] = useState([]);
        const [subCategories,setSubCategories] = useState([]);
        const [brands,setBrands] = useState([]);
        const [errors,setErrors] = useState({});
        const [saving,setSaving] = useState(false);
        const [productError,setProductError] = useState('');
        const [productLoading,setProductLoading] = useState(false);
        const [productData,setProductData] = useState({
            name:'',
            price:0,
            discountPrice:'',
            description:'',
            category:'',
            subcategory:'',
            capacity:'',
            unit:'',
            image:'',
            package_items_count:'',
            deliverable:true,
            featured:false,
            in_stock:true,
            variable:false,
            product_stock:0,
            imageUrl:null,
            variations:[
                {
                    price: 0,
                    capacity: 0,
                    discount_price: null,
                    product_stock:0,
                    items: 0,
                    unit: null,
                }
            ],
            remove_variations:[]
        });
        useEffect(() => {
            getCategories();
            getBrands();
            if(product_id && product_id != undefined){
                getProductData();
            }

        }, []);

        const addVariation = () => {
            let variations = productData.variations.concat({
                price: 0,
                capacity: 0,
                product_stock:0,
                discount_price: null,
                items: 0,
                unit: null,
            });
            setProductData({...productData,variations: variations});
        }

        const removeVariation = (index) => {
            let variation_data = productData.variations[index];
            let removing_var = [];
            if(typeof variation_data.id != undefined) {
               removing_var = productData.remove_variations.concat(variation_data);
            }

            let variations = productData.variations.filter((it,i) => i != index);

            setProductData({...productData,variations: variations,remove_variations: removing_var});
        }

        const getProductData = () => {
            setProductLoading(true);
            marketService.getProduct(product_id)
            .then((res) => {
                setProductData({...productData,
                    name:res.name,
                    capacity:res.capacity,
                    deliverable:res.deliverable,
                    featured:res.featured,
                    unit:res.unit,
                    price:res.price,
                    discountPrice:res.discount_price,
                    description:res.description,
                    brand:res.brand ? {value:res.brand.id,label:res.brand.name}:null,
                    category:{value:res.category.id,label:res.category.name},
                    sub_category:res.subcategory ? {value:res.subcategory.id,label:res.subcategory.name}:null,
                    package_items_count:res.package_items_count,
                    in_stock:res.in_stock,
                    product_stock:res.product_stock,
                    variable:res.variable,
                    variations: res.variations,
                    imageUrl: res.has_media ? res.media[0].url:null,
                });
                getSubCategories(res.category?.id);
            })
            .catch((err) => setProductError(err.message))
            .then(() => setProductLoading(false));
        }

        const getCategories = () => {
            initialService.getParentCategories()
            .then((res) => {
                setCategories(res.map((item,index) => {
                    return {
                        label:item.name,
                        value:item.id,
                    }
                    getSubCategories();
                }));
            });
        }

    const getSubCategories = (parent_id = '') => {
            const selectedParent = parent_id ? parent_id:productData.category.value;
            let query_data = {
            search: '',
            searchFields:'',
        };

        if(selectedParent){
            query_data.search = query_data.search + 'parent_id:'+selectedParent;
            query_data.searchFields = query_data.searchFields + 'parent_id:=;';
            query_data.parent_id = selectedParent;
        }

            initialService.getCategories(query_data)
                .then((res) => {
                    setSubCategories(res.map((item,index) => {
                        return {
                            label:item.name,
                            value:item.id,
                        }
                    }));
                });
    }


    const getBrands = () => {
        initialService.getBrands()
            .then((res) => {
                setBrands(res.map((item,index) => {
                    return {
                        label:item.name,
                        value:item.id,
                    }
                }));
            });
    }

        const validateForm = () => {
            let isValid = true;
            let err = [];
            if(productData.name == ''){
                err.name = 'Product name is required!';
                isValid = false;
            }


            if(!productData.variable){
                if(productData.price == ''){
                    err.price = 'Price is required!';
                    isValid = false;
                }

                if(productData.category == ''){
                    err.category = 'Please select atleast one category';
                    isValid = false;
                }

                if(productData.capacity == ''){
                    err.capacity = 'Please add capacity of product';
                    isValid = false;
                }

                if(productData.unit == ''){
                    err.unit = 'Please add product capacity unit (eg. Kg, g, L)';
                    isValid = false;
                }

                /*if(productData.package_items_count == ''){
                    err.package_items_count = 'Please package item count';
                    isValid = false;
                }*/
                if(productData.product_stock == ''){
                    err.product_stock = 'Please add available product stock, otherwise add 0';
                    isValid = false;
                }
            }
            if(productData.variable && productData.variations.length <= 0){
                isValid = false;
                alert('Please add atleast 1 variation');
            }
            setErrors(err);
            return isValid;
        }

        const getError = (name) => {
            return errors[name] !== undefined && errors[name] != '' ? errors[name]:false;
        }

        const onImageChange = (event) => {
            if(event.target.files.length <= 0){
              return;
            }
            mediaService.uploadFile(event.target.files[0])
            .then((res) => setProductData({...productData,image:res.uuid}));
        }

        const removeImage = () => {
            setProductData({...productData,image:''});
        }
        const submitProduct = () => {
            if(!validateForm()){
                return;
            }
            setSaving(true);
            const formData = {
                name:productData.name,
                price:productData.price,
                unit:productData.unit,
                capacity:productData.capacity,
                discount_price:productData.discountPrice,
                deliverable:productData.deliverable,
                featured:productData.featured,
                category_id:productData.category.value,
                sub_category_id:productData.sub_category.value,
                brand_id:productData.brand.value,
                description:productData.description,
                package_items_count:productData.package_items_count,
                product_stock:productData.product_stock,
                in_stock:productData.in_stock,
                variable:productData.variable,
                variations:productData.variations,
                remove_variations:productData.remove_variations,
            };


            if(productData.image){
                formData.image = productData.image;
            }
            marketService.saveProduct(formData,product_id)
            .then((res) =>{
                toast.success("Product Saved")
                history.goBack();
            })
            .catch((err) => console.log(err))
            .then(() => setSaving(false));
        }

        const changeHandle = (data) => {
            setProductData(data);
        }

        const changeParentCategory = (event) => {
            setProductData({
                ...productData,
                category: event
            });
            getSubCategories(event.value);
        }

        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <PageHeading name={"Manage Product"}/>
                    <div className="nk-block">
                        <div className="card">
                            <div className="card-inner">
                                <h5 className="card-title">Product Details</h5>

                                {!productLoading && !productError ? <>
                                    <div className="row g-3 align-center">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label for="product-name">Product Name</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" value={productData.name} onChange={(event) => setProductData({...productData,name:event.target.value})} className={"form-control " + (getError('name') ? 'error':'')} id="product-name"
                                                           placeholder="Product Name"/>
                                                    {getError('name') && <span className="error">{getError('name')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="product-category">Main Category</label>
                                                <div className="form-control-wrap">
                                                    <Select value={productData.category}
                                                            className={'selectable ' + (getError('category') ? 'error' : '')}
                                                            onChange={(event) => changeParentCategory(event)} options={categories}/>
                                                    {getError('category') &&
                                                    <span className="error">{getError('category')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="product-category">Sub Category</label>
                                                <div className="form-control-wrap">
                                                    <Select value={productData.sub_category}
                                                            className={'selectable ' + (getError('sub_category') ? 'error' : '')}
                                                            onChange={(event) =>  setProductData({
                                                                ...productData,
                                                                sub_category: event
                                                            })} options={subCategories}/>
                                                    {getError('sub_category') &&
                                                    <span className="error">{getError('sub_category')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="product-category">Brand</label>
                                                <div className="form-control-wrap">
                                                    <Select value={productData.brand}
                                                            className={'selectable ' + (getError('brand') ? 'error' : '')}
                                                            onChange={(event) => setProductData({
                                                                ...productData,
                                                                brand: event
                                                            })} options={brands}/>
                                                    {getError('brand') &&
                                                    <span className="error">{getError('brand')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="product-description">Description</label>
                                                <div className="form-control-wrap">
                                                    <textarea type="text" value={productData.description}
                                                              onChange={(event) => setProductData({
                                                                  ...productData,
                                                                  description: event.target.value
                                                              })} className="form-control " id="product-description"
                                                              placeholder="Discription">
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="row g-3 align-center">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="site-off">Variable Product</label>
                                                    <span className="form-note">Multiple Variations?</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" checked={productData.variable} onChange={(event) => setProductData({...productData,variable:!productData.variable})} className="custom-control-input"
                                                               name="reg-public" id="prod-type"/>
                                                        <label className="custom-control-label"
                                                               htmlFor="prod-type">{productData.variable ? 'Variable Product':'Standard Product'}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    {productData.variable ? <VariableProduct removeVariation={removeVariation} addVariation={addVariation} getError={getError} productData={productData} changeHandle={changeHandle}/>:<StandardProduct getError={getError} productData={productData} changeHandle={changeHandle}/>}

                                    <div className="row g-3 ">

                                        <div className="col-lg-12 mt-2">
                                            <div className="form-group">
                                                <label for="product-image">Product Image</label>
                                                <div className="form-control-wrap">
                                                <Imageselector imageUrl={productData.imageUrl ?? ''} onChange={onImageChange} removeImage={removeImage}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="site-off">Featured</label>
                                                <span className="form-note">Is this a featured product?</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={productData.featured} onChange={(event) => setProductData({...productData,featured:!productData.featured})} className="custom-control-input"
                                                           name="reg-public" id="prod-featured"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="prod-featured">{productData.featured ? 'Featured':'Not Featured'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="site-off">Deliverable
                                                    Product</label>
                                                <span className="form-note">Is this product available for delivery?</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={productData.deliverable} onChange={(event) => setProductData({...productData,deliverable:!productData.deliverable})} className="custom-control-input"
                                                           name="reg-public" id="prod-deliverable"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="prod-deliverable">{productData.deliverable ? 'Deliverable':'Not deliverable'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row g-3 align-center">
                                        <div className="col-lg-5">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="site-off">In Stock</label>
                                                <span className="form-note">Is this product in stock?</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={productData.in_stock} onChange={(event) => setProductData({...productData,in_stock:!productData.in_stock})} className="custom-control-input"
                                                           name="reg-public" id="prod-instock"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="prod-instock">{productData.in_stock ? 'In Stock':'Out of stock'}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-lg-7 offset-lg-5">
                                            <div className="form-group mt-2">
                                                <button type="button" disabled={saving} onClick={submitProduct} className="btn btn-lg btn-primary">{saving ? 'Saving...':'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    </div></>:
                                !productError && <span>Loading...</span>
                                }

                            {productError && <span>{productError}</span>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default AddProduct;
