import React, {useState,useEffect} from 'react';
import NkTbItem from "./components/NkTbItem";
import NkTbHead from "./components/NkTbHead";
import {Link,useHistory} from "react-router-dom";
import PageHeading from "../../components/header/PageHeading";
import Pagination from "react-js-pagination";
import {marketService} from '../../../services/service-markets';
import queryString from 'query-string';
const statusBadge = (order) => {
    if(!order.active){
        return (<span className="badge badge-sm badge-dot has-bg badge-danger d-none d-mb-inline-flex">Cancelled</span>);
    }


    switch(order.order_status_id){
        case 1:
            return (<span className="badge badge-sm badge-dot has-bg badge-secondary d-none d-mb-inline-flex">Order Received</span>);
            break;
        case 2:
            return (<span className="badge badge-sm badge-dot has-bg badge-info d-none d-mb-inline-flex">Preparing</span>);
            break;
        case 3:
            return (<span className="badge badge-sm badge-dot has-bg badge-primary d-none d-mb-inline-flex">Ready</span>);
            break;
        case 4:
            return (<span className="badge badge-sm badge-dot has-bg badge-warning d-none d-mb-inline-flex">On the way</span>);
            break;
        case 5:
            return (<span className="badge badge-sm badge-dot has-bg badge-success d-none d-mb-inline-flex">Delivered</span>);
            break;

    }
}

const Orders = (props) => {
    const history = useHistory();
    const [loading,setLoading] = useState(false);
    const [orders,setOrders] = useState([]);
    const [totalOrders,setTotalOrders] = useState(0);
    const params = queryString.parse(window.location.search);
    const [state,setState] = useState({
        limit:20,
        page:params.page && params.page != undefined ? params.page:1,
        term:params.term && params.term != undefined ? params.term:'',
        status:params.status && params.status != undefined ? params.status:'',
    });

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        getOrders();
    }, [state]);

    const applyFilter = ({term,status}) => {

        history.push(`/orders?page=${state.page}&term=${term}&status=${status}`);
        setState({
            ...state,
            page:1,
            term,
            status
        });
    }

    const getOrders = (pagenum = '') => {
	
        marketService.getOrders({page:pagenum ? pagenum:state.page,limit:state.limit,term:state.term,status:state.status})

        .then(res => {
            setOrders(res.orders);
            if(res.counts){
                setTotalOrders(res.counts);
            }
        },
        err => {
            alert(err.message);
        });
    }

    const handlePageChange = (page) => {
        history.push(`/orders?page=${page}&term=${state.term}&status=${state.status}`);
        setState({...state,page});
    }

    const nextPage = (page) => {
        setState({
            ...state,
            page:state.page+1
        });
        getOrders(state.page+1);
    }

    const prevPage = (page) => {
        if(state.page <= 1){
            return;
        }
        setState({
            ...state,
            page:state.page-1
        });
        getOrders(state.page-1);
    }
    const updateStatus = (order_id,status) => {
        if(window.confirm('Are you sure you want to update order status?')){
            marketService.updateOrderDetails({order_id,status})
            .then((res) => {
                getOrders();
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }
        return (
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <PageHeading
                        term={state.term}
                        status={state.status}
                        applyFilter={applyFilter} name={'Sales'}
                    />
                    <div className="nk-block">
                        <div className="nk-tb-list is-separate is-medium mb-3">
                            <NkTbHead  />
                            {orders.length > 0 && orders.map((value,index) =>
                                <NkTbItem updateStatus={updateStatus} order={value}/>
                            )}
                        </div>
                        {!loading && totalOrders > 0 &&
                        <div>
                            <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="« First Page"
                            lastPageText="Last Page »"
                            nextPageText="Next ⟩"
                            prevPageText="⟨ Prev"
                            activePage={state.page}
                            itemsCountPerPage={state.limit}
                            totalItemsCount={totalOrders}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            />
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
}

export default Orders;
