import React, {useEffect}from 'react'
import {Route,useHistory} from 'react-router-dom';
import Login from "../auth/login/Login";
import OtpPage from "../auth/otpPage/OtpPage";
import CreateStore from "../auth/createStore";
import {useSelector} from 'react-redux';
const AuthRoute = ({component: Component}) => {
    const history = useHistory();
    const {currentUser} = useSelector((state) => state.user);

    useEffect(() => {
        if(currentUser.id){
            history.push('/');
        }
    }, []);

    return (
        <Route render={props => (
            <div className="nk-app-root">
                <div className="nk-main ">
                <div className="nk-wrap nk-wrap-nosidebar">
                    <div className="nk-content ">
                                <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                                    <a href="#" className="toggle btn-white btn btn-icon btn-light"
                                       data-target="athPromo"><em className="icon ni ni-info"></em></a>
                                </div>
                                
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/login/otp" component={OtpPage}/>
                                <div className="nk-block nk-auth-footer">
                                    <div className="nk-block-between">
                                        <ul className="nav nav-sm">
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">Terms & Condition</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">Privacy Policy</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">Help</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mt-3">
                                        <p>&copy; 2020 Spacium Store. All Rights Reserved.</p>
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
        </div>
        )}
    />
)};

export default AuthRoute;
