import React,{useRef,useState} from 'react';

const Imageselector = (props) => {
    const inputFileRef = useRef( null );
    const [imageUrl,setImageUrl] = useState(props.imageUrl || '');
    const handleInputSelect = () =>{
        inputFileRef.current.click();
    }

    const _handleImageChange = (e) => {
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
        setImageUrl(reader.result);
        }
    
        reader.readAsDataURL(file)
    }

    const handleFileChange = (e) => {
        _handleImageChange(e);
        if(props.onChange) props.onChange(e);
    }

    const removeImage = () => {
        setImageUrl('');
        inputFileRef.current.value = '';
        if(props.removeImage) props.removeImage();
    }

    return (
        <div className="image-selector">
            <input ref={inputFileRef} type="file" name="file" style={{display:'none'}} onChange={handleFileChange} />
            <div onClick={handleInputSelect} className="imagePreview">
                <img src={imageUrl || "/assets/images/placeholder.png"}/>
            </div>
            <div className="action">
                { imageUrl ? 
                <button type="button" onClick={removeImage}className="btn btn-danger">Remove Image</button>:
                <button type="button" onClick={handleInputSelect} className="btn btn-primary">Select Image</button>
                }
            </div>
        </div>
    )
}

export default Imageselector;