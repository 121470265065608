import React, {Component} from 'react';
import NkTbItem from './NkTbItem';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const StatisticsColTwo = (props) => {
    return (
        <>
            <div className="col-xxl-12 col-md-12">
                <div className="card card-full">
                    <div className="card-inner">
                        <div className="card-title-group">
                            <div className="card-title d-flex flex-row justify-between w-100">
                                <h6 className="title">Recent Sales</h6>
                                <div className="toggle-wrap nk-block-tools-toggle">
                                    <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                       data-target="pageMenu"><em className="icon ni ni-more-v"></em></a>
                                    <div className="toggle-expand-content" data-content="pageMenu">
                                        <ul className="nk-block-tools g-3">
                                            <li>
                                                <div className="form-control-wrap d-flex flex-column mr-2">
                                                    <label htmlFor="" className="mb-1">From Date</label>
                                                    <DatePicker className={'form-control'} placeholder={'From Date'}
                                                                selected={props.startDate}
                                                                onChange={props.setStartDate}/>
                                                </div>
                                                <div className="form-control-wrap d-flex flex-column">
                                                    <label htmlFor="" className="mb-1">To Date</label>
                                                    <DatePicker className={'form-control'} selected={props.endDate}
                                                                onChange={props.setEndDate}/>
                                                </div>
                                            </li>
                                            <li>
                                                <button className="btn btn-primary mt-4" onClick={props.applyFilter}>
                                                    Filter
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-inner mb-n2">
                            <div className="card-title-group">
                                <div className="card-title d-flex justify-between w-100 card-title-sm">

                                    <h6 className="title">Product Sales</h6>
                                    <h6 className="title float-right">Total Earnings: {props.earnings}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="nk-tb-list is-loose traffic-channel-table">
                            <div className="nk-tb-item nk-tb-head">
                                <div className="nk-tb-col nk-tb-sessions"><span>Code</span></div>
                                <div className="nk-tb-col nk-tb-prev-sessions"><span>Name</span></div>
                                <div className="nk-tb-col nk-tb-prev-sessions"><span>Price</span></div>
                                <div className="nk-tb-col nk-tb-change"><span>Sales</span></div>
                            </div>
                            {props.products.length > 0 && props.products.map((product) => {
                                return (
                                    <div className="nk-tb-item">
                                        <div className="nk-tb-col nk-tb-channel">
                                            <span className="tb-lead">{product.code}</span>
                                        </div>
                                        <div className="nk-tb-col nk-tb-sessions">
                                            <span className="tb-sub tb-amount">{product.name}</span>
                                        </div>
                                        <div className="nk-tb-col nk-tb-sessions">
                                            <span className="tb-sub tb-amount">{product.price}</span>
                                        </div>
                                        <div className="nk-tb-col nk-tb-prev-sessions">
                                            <span className="tb-sub tb-amount">{product.total_sale}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default StatisticsColTwo;
